<!--<div *ngFor="let product of products$ | async">-->
<!--  {{ product.title }}-->
<!--</div>-->

<div class="banner-container">
  <app-pko-fibaro-banner type="small"></app-pko-fibaro-banner>
</div>

<div class="products">
  <app-product-card *ngFor="let product of products$ | async" [product]="product"></app-product-card>
</div>

<!--<app-help-info></app-help-info>-->

<app-cart-footer-summary [nextUrl]="'/koszyk'"></app-cart-footer-summary>

