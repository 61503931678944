import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CartViewModel, CartViewModelStore } from '../../services/cart-view-model.store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cart-summary-box',
  templateUrl: './cart-summary-box.component.html',
  styleUrls: ['./cart-summary-box.component.scss']
})
export class CartSummaryBoxComponent implements OnInit {

  @Input() label: string;
  @Input() next: string;
  @Input() type: 'long' | undefined;

  @Input() previousUrl: string;

  @Output() readonly nextClick = new EventEmitter<void>();

  cart: CartViewModel;

  private destroy$ = new Subject<void>();

  constructor(
    private cartViewModelStore: CartViewModelStore,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.cartViewModelStore.vm$.pipe(takeUntil(this.destroy$)).subscribe(vm => {
      this.cart = vm;
    });
    this.cartViewModelStore.load();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  goNext() {
    if (this.next) {
      this.router.navigate([this.next]);
    } else {
      this.nextClick.emit();
    }
  }
}
