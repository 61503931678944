<div class="card white user-settings-card">

  <div class="title">Twoje konto</div>

  <mat-accordion>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Dane i hasło</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="panel">
        <div class="panel-title">Dane</div>
        <div class="key-value">
          <span class="key">Imię i Nazwisko:</span>
          <span class="value">{{ user.name }} {{ user.surname }}</span>
        </div>
        <div class="key-value">
          <span class="key">E-mail:</span>
          <span class="value">{{ user.email }}</span>
        </div>
        <div class="key-value" style="margin-top: 18px;">
          <span class="key">tel.:</span>
          <span class="value">{{ user.phone }}</span>
        </div>
        <a [routerLink]="'/twoje-konto/edytuj-dane'" [routerLinkActive]="'active'" class="edit-link">Edytuj ›</a>
      </div>
      <div class="panel" style="margin-top: 10px;">
        <div class="panel-title">Hasło</div>
        <span>****</span>
        <a [routerLink]="'/resetuj-haslo'" [routerLinkActive]="'active'" class="edit-link">Zmień ›</a>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Adres dostawy</mat-panel-title>
      </mat-expansion-panel-header>

      <div *ngIf="user.street" class="panel">
        <div>{{ user.name }} {{ user.surname }}</div>
        <div>ul. {{ user.street }} {{ user.houseNo }}<span *ngIf="user.flatNo">/{{ user.flatNo }}</span></div>
        <div>{{ user.postalCode }} {{ user.city }}</div>
        <a [routerLink]="'/twoje-konto/edytuj-adres'" [routerLinkActive]="'active'" class="edit-link">Edytuj ›</a>
      </div>
      <div *ngIf="!user.street" class="panel">
        nie podano adresu dostawy
        <a [routerLink]="'/twoje-konto/edytuj-adres'" [routerLinkActive]="'active'" class="edit-link">Edytuj ›</a>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Zgody</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="panel">
        <mat-checkbox [checked]="user.fibaroMarketingConsent" [disabled]="true">Akceptuję <a href="#">zgody marketingowe</a> FIBARO</mat-checkbox>
        <a [routerLink]="'/twoje-konto/edytuj-zgody'" [routerLinkActive]="'active'" class="edit-link">Edytuj ›</a>
      </div>
    </mat-expansion-panel>

  </mat-accordion>

</div>

<!--<app-help-info></app-help-info>-->
