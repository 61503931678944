import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-expandable-text',
  templateUrl: './expandable-text.component.html',
  styleUrls: ['./expandable-text.component.scss']
})
export class ExpandableTextComponent implements OnInit {

  @Input() text: string;

  shortText: string;

  expanded = false;

  constructor() { }

  ngOnInit(): void {
    this.shortText = this.text.substr(0, 64);
  }

}
