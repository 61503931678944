import { Component, OnInit } from '@angular/core';
import { UserOrdersService } from '../../../shared/services/user-orders.service';
import { Observable } from 'rxjs';
import { Order } from '../../../shared/models/order';
import { img } from 'src/app/shared/utils/img';

@Component({
  selector: 'app-user-orders',
  templateUrl: './user-orders.component.html',
  styleUrls: ['./user-orders.component.scss']
})
export class UserOrdersComponent implements OnInit {

  readonly orders$: Observable<Order[]>;
  readonly image = img;

  constructor(private userOrdersService: UserOrdersService) {
    this.orders$ = this.userOrdersService.orders$;
  }

  ngOnInit(): void {
    this.userOrdersService.load(true).subscribe();
  }
}
