export const CORP_DOMAINS = [
  'pkobp.pl',
  'pkoubezpieczenia.pl',
  'pkofaktoring.pl',
  'finat.pl',
  'pkoleasing.pl',
  'pkopte.pl',
  'pkotfi.pl',
  'masterlease.pl',
  'pkobh.pl',
  'btksa.pl',
  'centrum-haffnera.eu',
  'ql.pl',
  'ochk.pl',
  'eservice.com.pl',
  'fibargroup.com',
];
