import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { nonEmptyStr, phoneNumber, validate, Validator, email } from '../../../shared/utils/validate';
import { Cart } from '../../../shared/services/cart.service';
import { AuthService } from '../../../shared/auth/services/auth.service';

export interface AccountForm {
  readonly name: string;
  readonly surname: string;
  readonly email: string;
  readonly phone: string;
  readonly password: string;
  readonly fibaroMarketingConsent: boolean;
  readonly termsAndPrivacyConsent: boolean;
}

@Component({
  selector: 'app-create-account-form',
  templateUrl: './create-account-form.component.html',
  styleUrls: ['./create-account-form.component.scss']
})
export class CreateAccountFormComponent implements OnInit {

  get name() { return this._name; }
  set name(value) {
    this.setAndValidateInput('name', value, validate(nonEmptyStr));
  }
  private _name = '';

  get surname() { return this._surname; }
  set surname(value) {
    this.setAndValidateInput('surname', value, validate(nonEmptyStr));
  }
  private _surname = '';

  get email() { return this._email; }
  set email(value) {
    this.setAndValidateInput('email', value, validate(nonEmptyStr, email));
  }
  private _email = '';

  get phone() { return this._phone; }
  set phone(value) {
    this.setAndValidateInput('phone', value, validate(nonEmptyStr, phoneNumber));
  }
  private _phone = '';

  get password() { return this._password; }
  set password(value) {
    this.setAndValidateInput('password', value, validate(nonEmptyStr));
  }
  private _password = '';


  nameError = false;
  surnameError = false;
  emailError = false;
  phoneError = false;
  passwordError = false;
  // @Output() readonly submitForm = new EventEmitter<DeliveryForm>();

  get enableSubmit() {
    return !(
      this.nameError
      || this.surnameError
      || this.emailError
      || this.phoneError
    );
  }
  constructor() { }

  ngOnInit(): void {
  }

  submit() {
  }

  private validateInputs() {
    const inputValidations: [string, Validator][] = [
      ['name', validate(nonEmptyStr)],
      ['surname', validate(nonEmptyStr)],
      ['email', validate(nonEmptyStr, email)],
      ['phone', validate(nonEmptyStr, phoneNumber)],
      ['password', validate(nonEmptyStr)],
    ];

    let valid = true;

    for (const [name, validator] of inputValidations) {
      const validInput = this.validateInput(name, this[name], validator);
      valid = valid && validInput;
    }

    return valid;

    // return inputValidations
    //   .reduce((valid, [name, validator]) => valid && this.validateInput(name, this[name], validator), true);
  }

  private setAndValidateInput(name: string, value: any, validator: Validator): boolean {
    this['_' + name] = value;
    return this.validateInput(name, value, validator);
  }

  private validateInput(name: string, value: any, validator: Validator): boolean {
    const valid = validator(value);
    this[name + 'Error'] = !valid;
    return valid;
  }

}
