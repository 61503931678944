import { Injectable } from '@angular/core';
import { BehaviorSubject, NEVER, Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PolicyNoForm } from '../../../shop/components/policy-no-form/policy-no-form.component';
import { User } from '../../models/user';
import { SignUpForm } from '../../../shop/components/sign-up-form/sign-up-form.component';
import { endpoint } from '../../utils/endpoint';
import { UserDataForm } from 'src/app/shop/components/user-data-form/user-data-form.component';
import { ChangePasswordForm } from '../../../shop/components/change-password-form/change-password-form.component';
import { UserDeliveryForm } from 'src/app/shop/components/user-delivery-form/user-delivery-form.component';
import { UserAgreementsForm } from 'src/app/shop/components/user-agreements-form/user-agreements-form.component';

export interface SignInResult {
  readonly ok: boolean;
  readonly user?: User;
  readonly error?: SignInError;
}

export enum SignInError {
  unauthorized = 'unauthorized'
}

export interface UserActivation {
  readonly email: string;
  readonly activationCode: string;
}

export interface UserUpdate {
  name?: string;
  surname?: string;
  email?: string;
  phone?: string;

  street?: string;
  houseNo?: string;
  flatNo?: string;
  postalCode?: string;
  city?: string;

  fibaroMarketingConsent?: boolean;
}

export interface SignUpResult {
  user?: User;
  userExistsError?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _user$ = new BehaviorSubject<User>(undefined);
  readonly user$ = this._user$.asObservable();
  get user() { return this._user$.getValue(); }

  get insuranceOnlySession() {
    return this.user && !this.user.userId;
  }

  constructor(private readonly http: HttpClient) {}

  signInPolicyNo(form: PolicyNoForm): Observable<User> {
    const url = `${environment.apiRoot}/insurances/verification`;
    return this.http.post(url, form, { withCredentials: true }).pipe(switchMap(() => this.fetchUser()));
  }

  signUp(signUpForm: SignUpForm, role: 'bank_employee'): Observable<SignUpResult> {
    const url = `${environment.apiRoot}/users/sign-up`;
    return this.http.post<User>(url, { ...signUpForm, role }, { withCredentials: true, observe: 'response' })
      .pipe(
        map(response => {
          return { user: response.body };
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400) {
            return of({ userExistsError: true });
          }
          return throwError(error);
        })
      );
  }

  signUpWithInsurance(signUpForm: SignUpForm, role: 'policy_owner'): Observable<SignUpResult> {
    const url = `${environment.apiRoot}/users/sign-up/insurance`;
    return this.http.post<User>(url, { ...signUpForm, role }, { withCredentials: true, observe: 'response' })
      .pipe(
        map(response => {
          return { user: response.body };
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400) {
            return of({ userExistsError: true });
          }
          return throwError(error);
        })
      );
  }

  activateUser(activation: UserActivation): Observable<void> {
    const url = `${environment.apiRoot}/users/activate`;
    return this.http.post<void>(url, activation, { withCredentials: true });
  }

  signIn(email: string, password: string, withInsurance?: boolean): Observable<SignInResult> {
    const url = `${environment.apiRoot}/users/sign-in${withInsurance ? '/insurance' : ''}`;
    const body = { name: email, password };
    this._user$.next(undefined);
    return this.http.post<string>(url, body, { withCredentials: true, observe: 'response' })
      .pipe(
        switchMap(response => {
          if (response.status === 200) {
            return this.fetchUser().pipe(map(user => ({ ok: true, user })));
          }
          return throwError(response);
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            return of({ ok: false, error: SignInError.unauthorized });
          }
          return throwError(error);
        })
      );
  }

  signOut(): Observable<void> {
    const url = `${environment.apiRoot}/users/sign-out`;
    return this.http.post<void>(url, null, { withCredentials: true })
      .pipe(
        tap(() => this._user$.next(undefined))
      );
  }

  autoSignIn(): Observable<User> {
    if (this.user) {
      return of(this.user);
    }
    return this.fetchUser();
  }

  resetPassword(email: string): Observable<void> {
    const url = endpoint('/users/password/reset');
    return this.http.post<void>(url, { email }, { withCredentials: true });
  }

  changePassword(newPassword: string, email: string, resetCode: string): Observable<void> {
    const url = endpoint('/users/password/change');
    return this.http.post<void>(url, { newPassword, email, resetCode }, { withCredentials: true });
  }

  updateUserData(form: UserDataForm): Observable<User> {
    return this.patchUser(form);
  }

  updateUserDelivery(form: UserDeliveryForm): Observable<User> {
    return this.patchUser(form);
  }

  updateUserAgreements(form: UserAgreementsForm): Observable<User> {
    return this.patchUser(form)
  }

  private patchUser(update: UserUpdate): Observable<User> {
    const url = endpoint('/users/me');
    return this.http.patch<User>(url, update, { withCredentials: true })
      .pipe(
        tap(user => this._user$.next(user))
      );
  }

  private fetchUser(): Observable<User> {
    const url = `${environment.apiRoot}/users/me`;
    return this.http.get<User>(url, { withCredentials: true })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            this._user$.next(undefined);
            return of(undefined);
          }
          return throwError(error);
        }),
        switchMap(user => {
          if (user && (user.role === 'admin' || user.role === 'super_admin' || user.role === 'backoffice')) {
            window.location.href = environment.adminUrl;
            return NEVER;
          }
          return of(user);
        }),
        map(user => {
          this._user$.next(user);
          return user;
        })
      );
  }
}
