import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-with-link',
  templateUrl: './icon-with-link.component.html',
  styleUrls: ['./icon-with-link.component.scss']
})
export class IconWithLinkComponent implements OnInit {

  @Input() src: string;
  @Input() alt: string;
  @Input() href = '#';

  constructor() { }

  ngOnInit(): void {
  }

}
