<form (ngSubmit)="submit()">

  <div class="form-input">
    <label for="email">E-mail*</label>
    <input [(ngModel)]="email" name="email" id="email">
    <div class="form-input-error" [style.opacity]="emailError ? 1 : 0">
      <ng-container *ngIf="!email else invalid_email">
        E-mail jest wymagany
      </ng-container>
      <ng-template #invalid_email>
        Niepoprawny e-mail
      </ng-template>
    </div>
  </div>

    <app-button type="submit" [disabled]="!enableSubmit || processing" [processing]="processing" size="small">
      RESETUJ HASŁO
    </app-button>
</form>
