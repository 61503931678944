import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { range } from '../../utils/range';

@Component({
  selector: 'app-step-display',
  templateUrl: './step-display.component.html',
  styleUrls: ['./step-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepDisplayComponent {
  @Input() current = 1;
  @Input()
  set count(value) {
    if (this._count === value) {
      return;
    }
    this._count = value;
    this.updateSteps();
  }
  get count() { return this._count; }
  private _count = 2;

  steps: number[];

  ngOnInit() {
    this.updateSteps();
  }

  private updateSteps() {
    this.steps = range(1, this._count);
  }
}
