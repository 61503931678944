import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Order } from '../models/order';
import { HttpClient } from '@angular/common/http';
import { endpoint } from '../utils/endpoint';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserOrdersService {
  private readonly _orders$ = new BehaviorSubject<Order[]>(undefined);
  readonly orders$ = this._orders$.asObservable();
  get orders() { return this._orders$.getValue(); }

  constructor(private http: HttpClient) {}

  load(reload = true): Observable<Order[]> {
    if (this.orders && !reload) {
      return of(this.orders);
    }
    const url = endpoint('/me/orders');
    this._orders$.next(undefined);
    return this.http.get<Order[]>(url, { withCredentials: true })
      .pipe(
        tap(orders => this._orders$.next(orders))
      );
  }
}
