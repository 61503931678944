import { Component } from '@angular/core';
import { AuthService } from '../../../shared/auth/services/auth.service';
import { ResetPasswordForm } from '../../components/reset-password-form/reset-password-form.component';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

  processing = false;
  mailSent = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private location: Location
  ) {}

  goBack(): void {
    this.location.back();
  }

  submit(form: ResetPasswordForm): void {
    this.processing = true;
    this.authService.resetPassword(form.email).pipe(finalize(() => this.processing = false)).subscribe(() => {
      this.mailSent = true;
    });
  }
}
