<form (ngSubmit)="submit()">

    <div class="form-input">
      <label for="street">Ulica</label>
      <input [(ngModel)]="street" name="street" id="street">
      <div class="form-input-error" [style.opacity]="streetError ? 1 : 0">Ulica jest wymagana</div>
    </div>
  
    <div class="form-input">
      <label for="houseNo">Numer domu</label>
      <input [(ngModel)]="houseNo" name="houseNo" id="houseNo">
      <div class="form-input-error" [style.opacity]="houseNoError ? 1 : 0">Numer domu jest wymagany</div>
    </div>
  
    <div class="form-input">
      <label for="flatNo">Numer mieszkania</label>
      <input [(ngModel)]="flatNo" name="flatNo" id="flatNo">
    </div>
  
    <div class="form-input">
      <label for="postalCode">Kod pocztowy</label>
      <input [(ngModel)]="postalCode" name="postalCode" id="postalCode">
      <div class="form-input-error" [style.opacity]="postalCodeError ? 1 : 0">Kod pocztowy jest wymagany</div>
    </div>
  
    <div class="form-input">
      <label for="city">Miasto</label>
      <input [(ngModel)]="city" name="city" id="city">
      <div class="form-input-error" [style.opacity]="cityError ? 1 : 0">Miasto jest wymagane</div>
    </div>
  
   <app-button type="submit" [disabled]="!enableSubmit || processing" [processing]="processing" size="small">
      ZMIEŃ
   </app-button>
   <a [routerLink]="'/twoje-konto'" [routerLinkActive]="'active'" class="back-link">Wróć ›</a>
  </form>
  