<ng-template #loading>
    <div class="loading">
        <mat-spinner diameter="25"></mat-spinner>
    </div>
</ng-template>

<div *ngIf="orders$ | async; let orders else loading" class="card white user-orders-card">

    <div class="title">Twoje zakupy</div>

    <div class="body" *ngIf="orders.length === 0">
      Nie masz żadnych zakupów.
    </div>

    <mat-accordion *ngIf="orders.length > 0">

      <mat-expansion-panel *ngFor="let order of orders">
        <mat-expansion-panel-header>
            <div class="header-content">
                <mat-panel-title>Zamówienie nr {{ order.id }}</mat-panel-title>
                <div class="subtitle">{{ order.createdAt | date:'dd.MM.yyyy' }}</div>
            </div>
        </mat-expansion-panel-header>
        <div class="status">
            Status:
            <span class="value-container">
                <span class="value">
                {{ order.status | order_status }}
                </span>
            </span>
        </div>

        <div class="panel">
            <div  class="products">
                <div *ngFor="let item of order.items" class="content">
                    <div class="product-container">
                        <img class="thumbnail" [src]="image(item.thumbnailUri)" [alt]="item.product">
                        <div class="name">
                            {{ item.product }} ({{ item.quantity }} szt.)
                        </div>
                    </div>
                    <app-discounted-price
                        [originalPrice]="item.price"
                        [discountedPrice]="item.discountedPrice">
                    </app-discounted-price>
                </div>
            </div>
            <div class="subpanel">
                <div class="panel-title">{{ (order.installation.type | installation_type).description }}</div>
                <div class="price-container">
                    <div class="price-label">Cena:</div>
                    <div class="price bold">{{ order.installation.price | pln }}</div>
                </div>
            </div>
            <div class="subpanel shipping">
                <div class="panel-title shipping">Bezpłatna dostawa</div>
                <div class="panel-subtitle">
                    Kurier DHL <br>
                    Przewidywany czas wysyłki - 3 dni robocze
                </div>
                <div class="price-container">
                    <div class="price-label">Cena:</div>
                    <div class="price bold">{{ 0 | pln }}</div>
                </div>
            </div>
            <div class="total-price price-container">
                <div class="price-label">Razem:</div>
                <div class="price original">{{ order.totalPrice | pln }}</div>
                <div class="price discounted">{{ order.totalDiscountedPrice | pln }}</div>
            </div>
        </div>
      </mat-expansion-panel>

    </mat-accordion>

  </div>

<!--  <app-help-info></app-help-info>-->
