import { Injectable } from '@angular/core';
import { CartService } from './cart.service';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

  totalSteps = 3;

  nextUrl = '/koszyk';

  constructor(private cartService: CartService) {
    this.cartService.cart$.subscribe(cart => {
      if (cart) {
        if (cart.installationMethod) {
          const onsiteInstallation = cart.installationMethod.type === 'onsite';

          this.totalSteps = onsiteInstallation ? 4 : 3;
          this.nextUrl = '/dostawa';

          if (cart.delivery) {
            this.nextUrl = onsiteInstallation ? '/adres-instalacji' : '/platnosc';
          }
        }
      }
    });
  }
}
