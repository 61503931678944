import { Component } from '@angular/core';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss']
})
export class SocialMediaComponent {
  readonly media = [
    medium('facebook', 'https://facebook.com/fibaro.poland/'),
    medium('instagram', 'https://instagram.com/fibaro/'),
    medium('twitter', 'https://twitter.com/fibaro_global'),
    medium('linkedin', 'https://linkedin.com/company/fibar-group'),
    medium('youtube', 'https://youtube.com/user/FibaroCom')
  ];
}

function medium(name: string, url: string) {
  return { name, url };
}
