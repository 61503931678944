import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductListComponent } from './shop/pages/product-list/product-list.component';
import { VerificationComponent } from './shop/pages/verification/verification.component';
import { AuthGuard } from './shared/auth/guards/auth.guard';
import { SignUpComponent } from './shop/pages/sign-up/sign-up.component';
import { SignInComponent } from './shop/pages/sign-in/sign-in.component';
import { ActivateUserComponent } from './shop/pages/activate-user/activate-user.component';
import { CartComponent } from './shop/pages/cart/cart.component';
import { InstallationMethodComponent } from './shop/pages/installation-method/installation-method.component';
import { DeliveryComponent } from './shop/pages/delivery/delivery.component';
import { InstallationAddressComponent } from './shop/pages/installation-address/installation-address.component';
import { OrderSummaryComponent } from './shop/pages/order-summary/order-summary.component';
import { UserSettingsComponent } from './shop/pages/user-settings/user-settings.component';
import { UserOrdersComponent } from './shop/pages/user-orders/user-orders.component';
import { UserDeliveryComponent } from './shop/pages/user-delivery/user-delivery.component';
import { UserDataComponent } from './shop/pages/user-data/user-data.component';
import { PaymentComponent } from './shop/pages/payment/payment/payment.component';
import { OrderConfirmationComponent } from './shop/pages/order-confirmation/order-confirmation.component';
import { ResetPasswordComponent } from './shop/pages/reset-password/reset-password.component';
import { ChangePasswordComponent } from './shop/pages/change-password/change-password.component';
import { UserAgreementsComponent } from './shop/pages/user-agreements/user-agreements.component';
import { CreateAccountComponent } from './shop/pages/create-account/create-account.component';
import { OrderAccountComponent } from './shop/pages/order-account/order-account.component';
import { SignUpWithInsuranceComponent } from './shop/pages/sign-up-with-insurance/sign-up-with-insurance.component';
import { SignInWithInsuranceComponent } from './shop/pages/sign-in-with-insurance/sign-in-with-insurance.component';
import { ProductCardComponent } from './shared/components/product-card/product-card.component';
import { ProductDetailsComponent } from './shop/pages/product-details/product-details.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: ProductListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'adres-instalacji',
    pathMatch: 'full',
    component: InstallationAddressComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'konto/:email/aktywacja/:activationCode',
    pathMatch: 'full',
    component: ActivateUserComponent
  },
  {
    path: 'konto-korporacyjne/logowanie',
    pathMatch: 'full',
    component: SignInComponent
  },
  {
    path: 'konto-korporacyjne/rejestracja',
    pathMatch: 'full',
    component: SignUpComponent
  },
  {
    path: 'konto-klienta/logowanie',
    pathMatch: 'full',
    component: SignInWithInsuranceComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'karta-produktu/:slug',
    pathMatch: 'full',
    component: ProductDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'konto',
    pathMatch: 'full',
    component: CreateAccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'koszyk',
    pathMatch: 'full',
    component: CartComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'platnosc',
    pathMatch: 'full',
    component: PaymentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'potwierdzenie',
    pathMatch: 'full',
    component: OrderConfirmationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'podsumowanie',
    pathMatch: 'full',
    component: OrderSummaryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sposob-instalacji',
    pathMatch: 'full',
    component: InstallationMethodComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'twoje-konto',
    pathMatch: 'full',
    component: UserSettingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'twoje-konto/edytuj-dane',
    pathMatch: 'full',
    component: UserDataComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'twoje-konto/edytuj-adres',
    pathMatch: 'full',
    component:  UserDeliveryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'twoje-konto/edytuj-zgody',
    pathMatch: 'full',
    component:  UserAgreementsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'twoje-zakupy',
    pathMatch: 'full',
    component: UserOrdersComponent,
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'konto',
  //   pathMatch: 'full',
  //   component: OrderAccountComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'dostawa',
    pathMatch: 'full',
    component: DeliveryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'resetuj-haslo',
    pathMatch: 'full',
    component: ResetPasswordComponent
  },
  {
    path: 'konto/:email/reset-hasla/:resetCode',
    pathMatch: 'full',
    component: ChangePasswordComponent
  },
  {
    path: 'weryfikacja',
    pathMatch: 'full',
    component: VerificationComponent
  }
];

@NgModule({
  // imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
