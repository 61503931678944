<app-step-display [current]="4" [count]="4"></app-step-display>

<div class="full-page-title">
  Adres instalacji
</div>

<div class="cards">

  <div class="card info">
    <div class="title">
      Ciesz się pełną usługą PKO Inteligentny dom.
    </div>
    <div class="body">
      Pamiętaj, aby zainstalować system FIBARO w domu/mieszkaniu, które obejmuje polisa PKO Dom.
    </div>
  </div>

  <div class="address-form-container">

    <div class="card white form-card">
      <div class="title">
        Adres instalacji
      </div>
      <div class="subtitle">
        * pola obowiązkowe
      </div>
      <div class="form-container">
        <app-installation-address-form *ngIf="cart$ | async" [cart]="cart$ | async" [processing]="processing" (submitForm)="submit($event)"></app-installation-address-form>
      </div>
    </div>

    <app-cart-summary-box [label]="'Zapłać'" (nextClick)="installationAddressForm.submit()" [previousUrl]="'/podsumowanie'"></app-cart-summary-box>

  </div>
</div>

<!--<app-help-info></app-help-info>-->

<app-cart-footer-summary [label]="'Zapłać'" (nextClick)="installationAddressForm.submit()" [previousUrl]="'/podsumowanie'"></app-cart-footer-summary>
