import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ProductDetails } from '../models/product-details';
import { HttpClient } from '@angular/common/http';
import { endpoint } from '../utils/endpoint';
import { map, tap } from 'rxjs/operators';
import { slugFrom } from '../utils/slugFrom';

@Injectable({
  providedIn: 'root'
})
export class ProductDetailsService {
  private readonly _productDetails$ = new BehaviorSubject<ProductDetails>(undefined);
  readonly productDetails$ = this._productDetails$.asObservable();
  get productDetails() { return this._productDetails$.getValue(); }

  constructor(private readonly http: HttpClient) {}

  loadProductDetails(slug: string, reload = true): Observable<ProductDetails> {
    if (this.productDetails && !reload) {
      return of(this.productDetails);
    }
    const url = endpoint(`/products/${slug}`);
    this._productDetails$.next(undefined);
    return this.http.get<ProductDetails>(url, { withCredentials: true })
      .pipe(
        map(productDetails => ({
          ...productDetails,
          category: {
            ...productDetails.category,
            slug: slugFrom(productDetails.category.name)
          }
        })),
        tap(productDetails => this._productDetails$.next(productDetails))
      );
  }
}
