<div class="card white">
  <ng-container *ngIf="paid else not_paid">
    <div class="title">
      Dziękujemy za zamówienie!
    </div>
    <div class="body">
      Potwierdzenie otrzymasz e-mailem. Zachęcamy do składania dalszych zamówień.
      <a [routerLink]="'/'" class="btn">Dalej</a>
    </div>
  </ng-container>

  <ng-template #not_paid>
    <div class="title">
      Płatność
    </div>
    <div class="body">
      Trwa oczekiwanie na płatność...
      <mat-spinner diameter="25"></mat-spinner>
    </div>
  </ng-template>
</div>
