import { Component, OnInit } from '@angular/core';
import { InstallationType } from '../../../shared/models/installation-type';
import { Cart, CartService, InstallationMethodPricing } from '../../../shared/services/cart.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../../../shared/auth/services/auth.service';

@Component({
  selector: 'app-installation-method',
  templateUrl: './installation-method.component.html',
  styleUrls: ['./installation-method.component.scss']
})
export class InstallationMethodComponent implements OnInit {

  readonly installationTypes = Object.keys(InstallationType);

  processing = false;

  selectedInstallationTypeId;

  readonly cart$: Observable<Cart>;

  get nextUrl() {
    return this.authService.insuranceOnlySession
      ? '/konto'
      : '/dostawa';
  }

  private destroy$ = new Subject<void>();

  constructor(
    private readonly authService: AuthService,
    private readonly cartService: CartService,
    private readonly router: Router
  ) {
    this.cart$ = this.cartService.cart$;
  }

  ngOnInit(): void {
    this.cartService.cart$.pipe(takeUntil(this.destroy$)).subscribe(cart => {
      this.selectedInstallationTypeId = cart.installationMethod && cart.installationMethod.id;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectInstallationType(installationMethodId: number): void {
    this.processing = true;
    this.cartService.selectInstallationMethod(installationMethodId).pipe(finalize(() => this.processing = false)).subscribe(() => {
      this.router.navigate(['/koszyk']);
    });
  }

  isSelected(pricing: InstallationMethodPricing): boolean {
    return this.selectedInstallationTypeId === pricing.id;
  }

  saveAndContinue() {
    if (!this.selectedInstallationTypeId) {
      return;
    }
    this.processing = true;
    this.cartService.selectInstallationMethod(this.selectedInstallationTypeId).pipe(finalize(() => this.processing = false)).subscribe(() => {
      this.router.navigate(['/koszyk']);
    });
  }
}
