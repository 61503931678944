import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { nonEmptyStr, validate, Validator } from '../../../shared/utils/validate';
import { User } from 'src/app/shared/models/user';

export interface UserDeliveryForm {
  street: string;
  houseNo: string;
  flatNo: string;
  postalCode: string;
  city: string;
}

@Component({
  selector: 'app-user-delivery-form',
  templateUrl: './user-delivery-form.component.html',
  styleUrls: ['./user-delivery-form.component.scss']
})
export class UserDeliveryFormComponent implements OnInit {
  @Input() user: User;
  @Input() processing: boolean;

  get street() { return this._street; }
  set street(value) {
    this.setAndValidateInput('street', value, validate(nonEmptyStr));
  }
  private _street = '';

  get houseNo() { return this._houseNo; }
  set houseNo(value) {
    this.setAndValidateInput('houseNo', value, validate(nonEmptyStr));
  }
  private _houseNo = '';

  get flatNo() { return this._flatNo; }
  set flatNo(value) {
    this.setAndValidateInput('flatNo', value, validate());
  }
  private _flatNo = '';

  get postalCode() { return this._postalCode; }
  set postalCode(value) {
    this.setAndValidateInput('postalCode', value, validate(nonEmptyStr));
  }
  private _postalCode = '';

  get city() { return this._city; }
  set city(value) {
    this.setAndValidateInput('city', value, validate(nonEmptyStr));
  }
  private _city = '';

  streetError = false;
  houseNoError = false;
  flatNoError = false;
  postalCodeError = false;
  cityError = false;

  @Output() readonly submitForm = new EventEmitter<UserDeliveryForm>();

  get enableSubmit() {
    return !(
      this.streetError
      || this.houseNoError
      || this.flatNoError
      || this.postalCodeError
      || this.cityError
    );
  }

  constructor() { }

  ngOnInit(): void {
    this.street = this.user.street;
    this.houseNo = this.user.houseNo;
    this.flatNo = this.user.flatNo;
    this.postalCode = this.user.postalCode;
    this.city = this.user.city;
  }

  submit() {
    if (!this.validateInputs()) {
      return;
    }
    if (!this.enableSubmit) {
      return;
    }
    this.submitForm.emit({
      street: this.street,
      houseNo: this.houseNo,
      flatNo: this.flatNo,
      postalCode: this.postalCode,
      city: this.city,
    });
  }

  private validateInputs() {
    const inputValidations: [string, Validator][] = [
      ['street', validate(nonEmptyStr)],
      ['houseNo', validate(nonEmptyStr)],
      ['postalCode', validate(nonEmptyStr)],
      ['city', validate(nonEmptyStr)],
    ];

    let valid = true;

    for (const [name, validator] of inputValidations) {
      const validInput = this.validateInput(name, this[name], validator);
      valid = valid && validInput;
    }

    return valid;

    // return inputValidations
    //   .reduce((valid, [name, validator]) => valid && this.validateInput(name, this[name], validator), true);
  }

  private setAndValidateInput(name: string, value: any, validator: Validator): boolean {
    this['_' + name] = value;
    return this.validateInput(name, value, validator);
  }

  private validateInput(name: string, value: any, validator: Validator): boolean {
    const valid = validator(value);
    this[name + 'Error'] = !valid;
    return valid;
  }
}
