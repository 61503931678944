<ng-container *ngIf="productDetails$ | async; let productDetails">
  <div class="header" [class.required-product]="productDetails.model.sellable === 'required'">
    <div class="category-banner">
      <img class="icon" *ngIf="productDetails.model.sellable !== 'required'" [src]="'/assets/category-icons/' + (productDetails.model.category.slug | category_icon) + '.png'" [alt]="productDetails.model.category.slug" />
      <div class="text">
        {{ productDetails.model.category.name }}
      </div>
    </div>

    <div class="product-photos">
      <div class="thumbnail">
        <a [href]="image(thumbnailUri)">
          <img [src]="image(thumbnailUri)">
        </a>
      </div>
      <div class="gallery">
        <a *ngFor="let uri of galleryUris" [href]="image(uri)">
          <img [src]="image(uri)"/>
        </a>
      </div>
    </div>

    <div class="basic-info">
      <div class="name">
        {{ productDetails.model.title }}
      </div>
      <app-discounted-price [discountedPrice]="productDetails.activeVariant.discountedPrice"
                            [originalPrice]="productDetails.activeVariant.price"></app-discounted-price>

      <div class="actions">
        <mat-select [(value)]="quantity">
          <mat-option *ngFor="let option of quantityOptions" [value]="option">{{ option }}</mat-option>
        </mat-select>
        <app-button class="add-to-cart-btn" size="small" (click)="addToCart()" [secondary]="productAdded"
                    [processing]="processing" [disabled]="processing || !productDetails.canBuy">
          {{ productAdded ? 'Dodano' : 'Dodaj do koszyka' }}
        </app-button>
      </div>

      <div class="availability">
        <div class="dot"></div>
        <div>produkt dostępny</div>
      </div>

      <div class="delivery">
        <div class="title">
          Bezpłatna dostawa
        </div>
        <div>
          Przewidywany czas: 3 dni robocze
        </div>
      </div>
    </div>
  </div>

  <div class="detail-sections mobile">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>Opis produktu</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="detail-section-body">
        <app-product-details-description [productDetails]="productDetails"></app-product-details-description>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>Specyfikacja techniczna</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="detail-section-body">
        <app-product-details-specs [productDetails]="productDetails"></app-product-details-specs>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>Przykłady użycia</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="detail-section-body">
        <app-product-details-use-cases [productDetails]="productDetails"></app-product-details-use-cases>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="productDetails.model.installationImageUris" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>Montaż</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="detail-section-body" style="display: flex; justify-content: center; align-items: center">
        <img [src]="image(mobileInstallationImageUri, 'installations')" style="max-width: 100%"/>
      </div>
    </mat-expansion-panel>

  </div>

  <div class="detail-sections desktop">
    <div class="simple-tabs">
      <div class="tab" [class.active]="activeTab === 0" (click)="activeTab = 0">
        Opis produktu
      </div>
      <div class="tab" [class.active]="activeTab === 1" (click)="activeTab = 1">
        Specyfikacja techniczna
      </div>
      <div class="tab" [class.active]="activeTab === 2" (click)="activeTab = 2">
        Przykłady użycia
      </div>
      <div *ngIf="productDetails.model.installationImageUris" class="tab" [class.active]="activeTab === 3" (click)="activeTab = 3">
        Montaż
      </div>
      <div class="underline"></div>
    </div>
    <div class="detail-section-body">
      <app-product-details-description *ngIf="activeTab === 0" [productDetails]="productDetails"></app-product-details-description>
      <app-product-details-specs *ngIf="activeTab === 1" [productDetails]="productDetails"></app-product-details-specs>
      <app-product-details-use-cases *ngIf="activeTab === 2" [productDetails]="productDetails"></app-product-details-use-cases>
      <div *ngIf="activeTab === 3" style="display: flex; justify-content: center; align-items: center">
        <img [src]="image(desktopInstallationImageUri, 'installations')" style="max-width: 100%"/>
      </div>
    </div>
  </div>
</ng-container>

<!--<app-help-info></app-help-info>-->

<app-cart-footer-summary [nextUrl]="'/koszyk'"></app-cart-footer-summary>
