import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { nonEmptyStr, phoneNumber, validate, Validator, email } from '../../../shared/utils/validate';
import { DeliveryForm } from '../delivery-form/delivery-form.component';
import { Cart } from '../../../shared/services/cart.service';

export interface InstallationAddressForm {
  street: string;
  houseNo: string;
  flatNo: string;
  postalCode: string;
  city: string;
  period: string;
  comment: string;
}

@Component({
  selector: 'app-installation-address-form',
  templateUrl: './installation-address-form.component.html',
  styleUrls: ['./installation-address-form.component.scss']
})
export class InstallationAddressFormComponent implements OnInit {
  @Input() cart: Cart;
  @Input() processing: boolean;

  get sameAsDelivery() { return this._sameAsDelivery; }
  set sameAsDelivery(value) {
    this._sameAsDelivery = value;
    if (this._sameAsDelivery) {
      this.setDeliveryAddressAsInstallationAddress();
    }
  }
  private _sameAsDelivery = false;

  get name() { return this._name; }
  set name(value) {
    this.setAndValidateInput('name', value, validate(nonEmptyStr));
  }
  private _name = '';

  get surname() { return this._surname; }
  set surname(value) {
    this.setAndValidateInput('surname', value, validate(nonEmptyStr));
  }
  private _surname = '';

  get street() { return this._street; }
  set street(value) {
    this.setAndValidateInput('street', value, validate(nonEmptyStr));
  }
  private _street = '';

  get houseNumber() { return this._houseNumber; }
  set houseNumber(value) {
    this.setAndValidateInput('houseNumber', value, validate(nonEmptyStr));
  }
  private _houseNumber = '';

  get flatNumber() { return this._flatNumber; }
  set flatNumber(value) {
    this.setAndValidateInput('flatNumber', value, validate());
  }
  private _flatNumber = '';

  get postalCode() { return this._postalCode; }
  set postalCode(value) {
    this.setAndValidateInput('postalCode', value, validate(nonEmptyStr));
  }
  private _postalCode = '';

  get city() { return this._city; }
  set city(value) {
    this.setAndValidateInput('city', value, validate(nonEmptyStr));
  }
  private _city = '';

  get email() { return this._email; }
  set email(value) {
    this.setAndValidateInput('email', value, validate(nonEmptyStr, email));
  }
  private _email = '';

  get phone() { return this._phone; }
  set phone(value) {
    this.setAndValidateInput('phone', value, validate(nonEmptyStr, phoneNumber));
  }
  private _phone = '';

  get comment() { return this._comment; }
  set comment(value) {
    this.setAndValidateInput('comment', value, validate());
  }
  private _comment = '';

  nameError = false;
  surnameError = false;
  streetError = false;
  houseNumberError = false;
  postalCodeError = false;
  cityError = false;
  emailError = false;
  phoneError = false;

  @Output() readonly submitForm = new EventEmitter<InstallationAddressForm>();

  get enableSubmit() {
    return !(
      this.nameError
      || this.surnameError
      || this.streetError
      || this.houseNumberError
      || this.postalCodeError
      || this.cityError
      || this.emailError
      || this.phoneError
    );
  }

  constructor() { }

  ngOnInit(): void {
    if (!this.cart.installationAddress) {
      return;
    }
    this.street = this.cart.installationAddress.street;
    this.houseNumber = this.cart.installationAddress.houseNo;
    this.flatNumber = this.cart.installationAddress.flatNo;
    this.postalCode = this.cart.installationAddress.postalCode;
    this.city = this.cart.installationAddress.city;
    this.comment = this.cart.installationAddress.comment;

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.sameAsDelivery = true;
    });
  }

  submit() {
    if (!this.validateInputs()) {
      return;
    }
    if (!this.enableSubmit) {
      return;
    }
    this.submitForm.emit({
      street: this.street,
      city: this.city,
      flatNo: this.flatNumber,
      houseNo: this.houseNumber,
      postalCode: this.postalCode,
      period: '',
      comment: this.comment
    });
  }

  private validateInputs() {
    const inputValidations: [string, Validator][] = [
      ['street', validate(nonEmptyStr)],
      ['houseNumber', validate(nonEmptyStr)],
      ['postalCode', validate(nonEmptyStr)],
      ['city', validate(nonEmptyStr)],
    ];

    let valid = true;

    for (const [name, validator] of inputValidations) {
      const validInput = this.validateInput(name, this[name], validator);
      valid = valid && validInput;
    }

    return valid;

    // return inputValidations
    //   .reduce((valid, [name, validator]) => valid && this.validateInput(name, this[name], validator), true);
  }

  private setAndValidateInput(name: string, value: any, validator: Validator): boolean {
    this['_' + name] = value;
    return this.validateInput(name, value, validator);
  }

  private validateInput(name: string, value: any, validator: Validator): boolean {
    const valid = validator(value);
    this[name + 'Error'] = !valid;
    return valid;
  }

  private setDeliveryAddressAsInstallationAddress() {
    this.street = this.cart.delivery.street;
    this.houseNumber = this.cart.delivery.houseNo;
    this.flatNumber = this.cart.delivery.flatNo;
    this.postalCode = this.cart.delivery.postalCode;
    this.city = this.cart.delivery.city;
  }
}
