import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'category_icon',
  pure: true
})
export class CategoryIconPipe implements PipeTransform {
  readonly map: { [key: string]: string } = {
    'centrala': 'ikona_instalator',
    'ochrona-przed-zalaniem': 'ikona_zalanie',
    'ochrona-przed-pozarem': 'ikona_pozar',
    'ochrona-przed-wlamaniem': 'ikona_wlamanie',
    'ochrona-przed-czadem': 'ikona_pozar', // TODO (KH): add missing asset; asset non-downloadable from mockups
    'oszczednosci': 'ikona_oszczednosci'
  };

  transform(value: any, ...args: any[]): any {
    return this.map[value];
  }
}
