<app-step-display [current]="2" [count]="workflowService.totalSteps"></app-step-display>

<div class="full-page-title">
  Dostawa i Twoje dane
</div>

<div class="cards">

  <div class="delivery-form-container">
    <div class="card white delivery-card">
      <div class="title">
        Sposób dostawy
      </div>
      <div class="body">
        Kurier UPS
        <br>
        <small>(bezpłatna)</small>
        <br>
        <small>Przewidywany czas wysyłki: 3 dni robocze</small>
      </div>
    </div>

    <div class="card white">
      <div class="title">
        Dane odbiorcy
      </div>
      <div class="subtitle">
        * pola obowiązkowe
      </div>
      <div class="form-container">
        <app-delivery-form *ngIf="cart$ | async" [cart]="cart$ | async" [processing]="processing" (submitForm)="submit($event)"></app-delivery-form>
      </div>
    </div>
  </div>

  <app-cart-summary-box (nextClick)="deliveryForm.submit()" [previousUrl]="'/koszyk'"></app-cart-summary-box>
</div>

<!--<app-help-info></app-help-info>-->

<app-cart-footer-summary (nextClick)="deliveryForm.submit()" [previousUrl]="'/koszyk'"></app-cart-footer-summary>
