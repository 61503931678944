import { Component, Output, EventEmitter, Input, HostBinding } from '@angular/core';
import { matchAll } from '../../utils/match-all';
import { replaceAt } from '../../utils/replace-at';

@Component({
  selector: 'app-policy-no-input',
  templateUrl: './policy-no-input.component.html',
  styleUrls: ['./policy-no-input.component.scss']
})
export class PolicyNoInputComponent {
  @Input()
  @HostBinding('class.error')
  invalidPolicyNoError = true;

  @Output() readonly invalidPolicyNoErrorChange = new EventEmitter<boolean>();

  @Output() readonly policyNoChange = new EventEmitter<string>();

  get policyNo() { return this._policyNo; }
  set policyNo(value) {
    this._policyNo = value;
    this.notifyChange();
  }
  private _policyNo = '';

  private readonly policyNoCharRegex = /[a-z0-9]/i;

  onKeyDown($event: KeyboardEvent): void {
    // this.setInvalidPolicyError(false);
    // $event.preventDefault();
    // const key = $event.key;
    // if (key === 'Backspace') {
    //   this.clearLastChar();
    //   return;
    // }
    // if (key === 'Delete') {
    //   this.clearFirstChar();
    //   return;
    // }
    // if (!this.isValidPolicyNoChar(key)) {
    //   return;
    // }
    // this.policyNo = this.policyNo.replace('_', key);
  }

  private isValidPolicyNoChar(key: string): boolean {
    return key.length === 1 && key.match(/[a-z0-9]/i) !== null;
  }

  private clearFirstChar(): void {
    this.clearChar(chars => chars[0]);
  }

  private clearLastChar(): void {
    this.clearChar(chars => chars[chars.length - 1]);
  }

  private clearChar(selector: (matches: RegExpExecArray[]) => RegExpExecArray): void {
    const chars = this.getChars();
    const char = selector(chars);
    if (!char) {
      return;
    }
    this.policyNo = replaceAt(this.policyNo, char.index, '_');
  }

  private getChars(): RegExpExecArray[] {
    return [...matchAll(/[a-z0-9]/ig, this.policyNo)];
  }

  private notifyChange(): void {
    const value = this.policyNo.replace(/[\s_]/g, '');
    this.policyNoChange.emit(value);
  }

  private setInvalidPolicyError(error: boolean): void {
    this.invalidPolicyNoError = error;
    this.invalidPolicyNoErrorChange.emit(error);
  }
}
