import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatus } from '../models/order';

@Pipe({
  name: 'order_status',
  pure: true,
})
export class OrderStatusPipe implements PipeTransform {
  transform(value: OrderStatus, ...args: any[]): string {
    switch (value) {
      case OrderStatus.waiting_for_payment:
        return 'Oczekiwanie na płatność';
      case OrderStatus.paid:
        return 'Zapłacono';
      case OrderStatus.payment_cancelled:
        return 'Płatność anulowana';
      case OrderStatus.in_progress:
        return 'W realizacji';
      case OrderStatus.delivery_shipped:
        return 'Przesyłka wysłana';
      case OrderStatus.delivery_completed:
        return 'Przesyłka odebrana';
      case OrderStatus.stale:
        return 'Przestarzałe';
      case OrderStatus.completed:
        return 'Zrealizowano';
      default:
        throw new Error(`Unknown order status: ${value}`);
    }
  }
}
