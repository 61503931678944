<div class="header">
  {{ title || 'PKO Inteligentny dom' }}
</div>

<ng-container *ngIf="type === 'smart-home'">
  <div class="badge">
    <img src="/assets/policy.png" alt="polisa" />
    <div>
      Polisa<br>PKO Dom
    </div>
  </div>
  <img src="/assets/plus.svg" alt="plus" style="margin-top: 0.5rem" class="sign" />
  <div class="badge">
    <img src="/assets/devices.png" alt="urządzenia" style="margin-top: 1rem" />
    <div>
      Urządzenia inteligentnego domu<br>FIBARO
    </div>
  </div>
  <img src="/assets/equals.svg" alt="równa się" class="sign" />
  <div class="badge">
    <img src="/assets/service_mobile.png" alt="usługa" />
    <div>
      Wsparcie w ochronie<br>Twojego domu 24h/7dni
    </div>
  </div>
</ng-container>
