<div class="header">Wpisz numer polisy</div>
<p>mieszkania lub domu, w którym chcesz zainstalować urządzenia</p>

<form (ngSubmit)="submit()">
  <app-policy-no-input (policyNoChange)="policyNo = $event" [(invalidPolicyNoError)]="invalidPolicyNoError"></app-policy-no-input>
  <div class="input-help">
    <div class="content">
      Zobacz, gdzie możesz znaleźć numer polisy
    </div>
    <div class="tooltip">
      <div class="text">
        Numer polisy znajdziesz tuż pod nagłówkiem z lewej strony dokumentu
      </div>
      <img src="assets/images/gdzie_znalezc_numer.jpg" />
    </div>
  </div>
  <div class="consents">
    <mat-checkbox [(ngModel)]="allConsents" class="all-consents-checkbox" name="allConsents" [disableRipple]="true">
      Zaakceptuj wszystkie zgody
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="secretConsent" name="offerConsent" [disableRipple]="true">
      Wyrażam zgodę na ujawnienie tajemnicy ubezpieczeniowej*
    </mat-checkbox>
    <div class="smaller-text">
      <app-expandable-text [text]="'Wyrażam zgodę na ujawnienie FIBAR GROUP S.A. przez PKO Towarzystwo Ubezpieczeń S.A.\n'+
'      informacji o zawarciu przeze mnie umowy ubezpieczenia na podstawie WU PKO Dom, numeru polisy,\n'+
'      potwierdzającej zawarcie tej umowy, oraz informacji o rozwiązaniu tej umowy, w celu weryfikacji\n'+
'      mojego prawa do uczestnictwa w Programie PKO Dom oraz korzystania z tego programu.'"></app-expandable-text>
    </div>
    <mat-checkbox [(ngModel)]="smartHomeConsent" name="secretConsent" [disableRipple]="true">
      Akceptuję <a href="https://pkoubezpieczenia.pl/sites/default/files/2021-08/REGULAMIN-PKO-INTELIGENTNY-DOM.pdf" rel="noopener nofollow noreferrer" target="_blank">Regulamin Programu PKO Dom</a>*
    </mat-checkbox>
    <div class="smaller-text">
      <app-expandable-text [text]="'Potwierdzam, że udostępniono mi Regulamin Programu PKO Dom, akceptuję jego treść oraz\n'+
'        spełniam wszystkie warunki uczestnictwa w programie, wymienione w tym Regulaminie.'"></app-expandable-text>
    </div>
    <div class="consents-help">* zgody obowiązkowe</div>
    <div class="error" [style.opacity]="consentError && !allConsents ? 1 : 0">
      <div class="message">Zaznacz wymagane zgody</div>
    </div>
  </div>
<!--  <button class="btn" type="submit" [class.disabled]="!enableSubmit" (click)="submit()">Dalej</button>-->
  <app-button type="submit" (click)="submit()" [disabled]="!enableSubmit" [processing]="processing" wide="true">Dalej ›</app-button>
</form>
