import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-fibaro-item',
  templateUrl: './footer-fibaro-item.component.html',
  styleUrls: ['./footer-fibaro-item.component.scss']
})
export class FooterFibaroItemComponent {
  @HostBinding('class.footer-links') footerLinks = true;
}
