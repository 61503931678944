import { Component, EventEmitter, Input, Output } from '@angular/core';
import { nonEmptyStr, password, validate, Validator } from '../../../shared/utils/validate';

export interface ChangePasswordForm {
  newPassword: string;
}

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent {
  @Input() processing = false;

  get password() { return this._password; }
  set password(value) {
    this.setAndValidateInput('password', value, validate(nonEmptyStr, password));
  }
  private _password = '';

  get repeatedPassword() { return this._repeatedPassword; }
  set repeatedPassword(value) {
    this.setAndValidateInput('repeatedPassword', value, validate(nonEmptyStr, password, x => x === this.password));
  }
  private _repeatedPassword = '';

  repeatedPasswordError = false;

  @Output() readonly submitForm = new EventEmitter<ChangePasswordForm>();

  get enableSubmit() {
    return !this.repeatedPasswordError;
  }

  submit() {
    if (!this.validateInputs()) {
      return;
    }
    if (!this.enableSubmit) {
      return;
    }
    this.submitForm.emit({
      newPassword: this.password
    });
  }

  private validateInputs() {
    const inputValidations: [string, Validator][] = [
      ['password', validate(nonEmptyStr, password)],
      ['repeatedPassword', validate(nonEmptyStr, password, x => x === this.password)]
    ];

    let valid = true;

    for (const [name, validator] of inputValidations) {
      const validInput = this.validateInput(name, this[name], validator);
      valid = valid && validInput;
    }

    return valid;
  }

  private setAndValidateInput(name: string, value: any, validator: Validator): boolean {
    this['_' + name] = value;
    return this.validateInput(name, value, validator);
  }

  private validateInput(name: string, value: any, validator: Validator): boolean {
    const valid = validator(value);
    this[name + 'Error'] = !valid;
    return valid;
  }
}
