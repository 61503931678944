import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { Cart, CartService } from '../../services/cart.service';
import { ProductService } from '../../services/product.service';
import { filter, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CartItemViewModel, CartViewModel } from '../../services/cart-view-model.store';

@Component({
  selector: 'app-cart-footer-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.scss']
})
export class CartSummaryComponent implements OnInit {

  @Input() label: string;
  @Input() nextUrl: string;
  @Input() previousUrl: string;

  @Output() readonly nextClick = new EventEmitter<void>();

  readonly cart$: Observable<Cart>;

  vm: CartViewModel;

  get disabled() {
    return !this.vm || this.vm.additional.length === 0;
  }

  constructor(
    private readonly cartService: CartService,
    private readonly productService: ProductService,
    private readonly router: Router
  ) {
    this.cart$ = this.cartService.cart$;
  }

  ngOnInit(): void {
    combineLatest([
      this.cartService.cart$,
      this.productService.products$
    ])
      .pipe(
        filter(tuple => tuple.every(x => !!x)),
        map(([cart, products]) => ({
          cart,
          items: cart.items.map(item => ({
            item,
            product: products.find(x => x.id === item.productId),
            discountPercentage: Math.round((1 - item.variant.discountedPrice / item.variant.pricePerUnit) * 100),
            totalPrice: Math.round(item.variant.pricePerUnit * item.quantity * 100) / 100,
            totalDiscountedPrice: Math.round(item.variant.discountedPrice * item.quantity * 100) / 100
          })) as CartItemViewModel[]
        }))
      )
      .subscribe(({ cart, items }) => {
        this.vm = {
          model: cart,
          required: items.filter(x => x.item.required),
          additional: items.filter(x => !x.item.required)
        };
      });

    this.productService.loadProducts().subscribe();
  }

  goToNext() {
    if (this.disabled) {
      return;
    }

    if (this.nextUrl) {
      this.router.navigate([this.nextUrl]);
      return;
    }

    this.nextClick.emit();

    // if (this.vm.additional.length > 0) {
    //   // if (this.vm.model.delivery && this.vm.model.installationMethod) {
    //   //   this.router.navigate(['/adres-instalacji']);
    //   // } else
    //
    //     if (this.vm.model.installationMethod) {
    //     this.router.navigate(['/dostawa']);
    //   } else {
    //     this.router.navigate(['/sposob-instalacji']);
    //   }
    // }
  }
}
