<form (ngSubmit)="submit()">
  <div class="form-input">
    <label for="email">E-mail*</label>
    <input [(ngModel)]="email" name="email" id="email">
<!--    <div class="form-input-error" [style.opacity]="nameError ? 1 : 0">Imię jest wymagane</div>-->
  </div>
  <div class="form-input">
    <label for="password">Hasło*</label>
    <input type="password" [(ngModel)]="password" name="password" id="password">
    <div class="form-input-error" [style.opacity]="unauthorizedError ? 1 : 0">
      Niepoprawny e-mail lub hasło
    </div>
  </div>

  <div class="small-text">
    Nie pamiętasz <a [routerLink]="'/resetuj-haslo'">hasła</a>?
  </div>

  <app-button type="submit" [disabled]="!enableSubmit || processing" [processing]="processing" size="small">
    ZALOGUJ SIĘ
  </app-button>
</form>
