import { Component, OnDestroy, OnInit } from '@angular/core';
import { Cart, CartItem, CartService } from '../../../shared/services/cart.service';
import { combineLatest, Observable, Subject, zip } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ProductService } from '../../../shared/services/product.service';
import { Product } from '../../../shared/models/product';
import { range } from '../../../shared/utils/range';
import { Router } from '@angular/router';
import { CartItemViewModel, CartViewModel } from '../../../shared/services/cart-view-model.store';
import { AuthService } from '../../../shared/auth/services/auth.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnDestroy {

  readonly cart$: Observable<Cart>;

  readonly quantityOptions = range(1, 10);

  cart: CartViewModel;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly authService: AuthService,
    private readonly cartService: CartService,
    private readonly productService: ProductService,
    private readonly router: Router
  ) {
    this.cart$ = this.cartService.cart$;
  }

  ngOnInit(): void {
    combineLatest([
      this.cartService.cart$,
      this.productService.products$
    ])
    .pipe(
      filter(tuple => tuple.every(x => !!x)),
      map(([cart, products]) => ({
        cart,
        items: cart.items.map(item => ({
          item,
          product: products.find(x => x.id === item.productId),
          discountPercentage: Math.round((1 - item.variant.discountedPrice / item.variant.pricePerUnit) * 100),
          totalPrice: Math.round(item.variant.pricePerUnit * item.quantity * 100) / 100,
          totalDiscountedPrice: Math.round(item.variant.discountedPrice * item.quantity * 100) / 100
        })) as CartItemViewModel[]
      }))
    )
    .subscribe(({ cart, items }) => {
      this.cart = {
        model: cart,
        required: items.filter(x => x.item.required),
        additional: items.filter(x => !x.item.required)
      };
    });

    this.productService.loadProducts().subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  clearInstallationMethod() {
    this.cartService.clearInstallationMethod().subscribe();
  }

  goToProductList() {
    this.router.navigate(['/']);
  }

  goNext() {
    if (this.cart.additional.length === 0) {
      this.router.navigate(['/']);
      return;
    }
    if (this.cart.additional.length > 0) {
      // if (this.cart.model.delivery && this.cart.model.installationMethod) {
      //   this.router.navigate(['/adres-instalacji']);
      // } else if (this.cart.model.installationMethod) {
      //   this.router.navigate(['/dostawa']);
      // } else {
      //   this.router.navigate(['/sposob-instalacji']);
      // }

      if (this.cart.model.installationMethod) {
        if (this.authService.insuranceOnlySession) {
          this.router.navigate(['/konto']);
        } else {
          this.router.navigate(['/dostawa']);
        }
      } else {
        this.router.navigate(['/sposob-instalacji']);
      }
    }
  }
}
