import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { nonEmptyStr, phoneNumber, validate, Validator, email } from '../../../shared/utils/validate';
import { User } from 'src/app/shared/models/user';

export interface UserDataForm {
  name: string;
  surname: string;
  email: string;
  phone: string;
}

@Component({
  selector: 'app-user-data-form',
  templateUrl: './user-data-form.component.html',
  styleUrls: ['./user-data-form.component.scss']
})
export class UserDataFormComponent implements OnInit {
  @Input() user: User;
  @Input() processing: boolean;

  get name() { return this._name; }
  set name(value) {
    this.setAndValidateInput('name', value, validate(nonEmptyStr));
  }
  private _name = '';

  get surname() { return this._surname; }
  set surname(value) {
    this.setAndValidateInput('surname', value, validate(nonEmptyStr));
  }
  private _surname = '';

  get email() { return this._email; }
  set email(value) {
    this.setAndValidateInput('email', value, validate(nonEmptyStr, email));
  }
  private _email = '';

  get phone() { return this._phone; }
  set phone(value) {
    this.setAndValidateInput('phone', value, validate(nonEmptyStr, phoneNumber));
  }
  private _phone = '';

  nameError = false;
  surnameError = false;
  emailError = false;
  phoneError = false;

  @Output() readonly submitForm = new EventEmitter<UserDataForm>();

  get enableSubmit() {
    return !(
      this.nameError
      || this.surnameError
      || this.emailError
      || this.phoneError
    );
  }

  constructor() { }

  ngOnInit(): void {
    this.name = this.user.name;
    this.surname = this.user.surname;
    this.email = this.user.email;
    this.phone = this.user.phone;
  }

  submit() {
    if (!this.validateInputs()) {
      return;
    }
    if (!this.enableSubmit) {
      return;
    }
    this.submitForm.emit({
      name: this.name,
      surname: this.surname,
      email: this.email,
      phone: this.phone,
    });
  }

  private validateInputs() {
    const inputValidations: [string, Validator][] = [
      ['name', validate(nonEmptyStr)],
      ['surname', validate(nonEmptyStr)],
      ['email', validate(nonEmptyStr, email)],
      ['phone', validate(nonEmptyStr, phoneNumber)],
    ];

    let valid = true;

    for (const [name, validator] of inputValidations) {
      const validInput = this.validateInput(name, this[name], validator);
      valid = valid && validInput;
    }

    return valid;

    // return inputValidations
    //   .reduce((valid, [name, validator]) => valid && this.validateInput(name, this[name], validator), true);
  }

  private setAndValidateInput(name: string, value: any, validator: Validator): boolean {
    this['_' + name] = value;
    return this.validateInput(name, value, validator);
  }

  private validateInput(name: string, value: any, validator: Validator): boolean {
    const valid = validator(value);
    this[name + 'Error'] = !valid;
    return valid;
  }

}
