import { CORP_DOMAINS } from '../models/corp-domains.model';

export const required = value => !!value;

export const nonEmptyStr = (value: string) => {
  if (!value) {
    return false;
  }
  return value.trim().length > 0;
};

export const email = (value: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
};

export const phoneNumber = (value: string) => {
  const sanitized = value
    .replace('+48', '')
    .replace(/[- ]/g, '');
  const re = /^([0-9]){9}$/g;
  return re.test(sanitized);
};

// password has at least 8 characters and contains and least one number
export const password = (value: string) => {
  value = value.trim();
  return value.length >= 8 && /[0-9]/g.test(value);
};

export type ValidationResult = true | false | string;

export type Validator = (value: any) => boolean;
export type Modifier = (validator: Validator) => Validator;

export const validate = (...validators: ((x: any) => boolean)[]) => (x: any) => {
  return validators.reduce((valid, f) => valid && f(x), true);
};


export const not = (validator: Validator) => (x: any) => {
  const result = validator(x);
  return !result;
};

export const some = (validator: Validator) => (x: any) => {
  for (const item of x) {
    if (validator(item)) {
      return true;
    }
  }
  return false;
};

export const every = (validator: Validator) => (x: any) => {
  for (const item of x) {
    if (!validator(item)) {
      return false;
    }
  }
  return true;
};

export const empty = (x: any) => x.length === 0;
export const uppercase = (x: any) => x === x.toUpperCase();
export const lowercase = (x: any) => x === x.toLowerCase();

export const corpEmail = (corp: boolean) => (x: any) => {
  if (!corp) {
    return true;
  }
  return CORP_DOMAINS.some(domain => x.includes(domain));
};
