import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  @Input() disabled = false;
  @Input() wide = false;
  @Input() size: undefined | 'small' = undefined;
  @Input() secondary = false;

  @Input()
  set processing(value) {
    this._processing = value;
  }
  get processing() { return this._processing; }
  private _processing: boolean | undefined;

  @Output() readonly click = new EventEmitter<MouseEvent>();

  constructor() { }

  ngOnInit(): void {
  }

  get contentOpacity() {
    if (this.processing === undefined) {
      return 1;
    }
    return this.processing ? 0 : 1;
  }
}
