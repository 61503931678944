import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Product } from '../../models/product';
import { environment } from '../../../../environments/environment';
import { range } from '../../utils/range';
import { ProductVariant } from '../../models/product-variant';
import { Cart, CartService } from '../../services/cart.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProductViewModel, ProductViewModelStore } from '../../services/product-view-model.store';
import { img } from '../../utils/img';
import { calculateLimitedQuantityWithOptions } from '../../utils/limited-quantity';
import { AuthService } from '../../auth/services/auth.service';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit, OnDestroy {

  @Input() product: ProductViewModel;

  get activeVariant() { return this._activeVariant; }
  set activeVariant(value) {
    if (this._activeVariant === value) {
      return;
    }
    this._activeVariant = value;
    this.updateVariant();
    this.productViewModelStore.activateVariant(value);
  }
  private _activeVariant: ProductVariant;

  get quantity() {
    return this._quantity;
  }
  set quantity(value) {
    if (this._quantity === value) {
      return;
    }

    this._quantity = value;

    if (this.productAdded) {
      this.updateCart();
    }
  }
  private _quantity = 1;

  quantityOptions: number[];

  productAdded = false;

  @HostBinding('class.required')
  get required() {
    return this.product.model.sellable === 'required';
  }

  get thumbnailUri() {
    return this.product.activeVariant.imageUris[0];
  }

  apiRoot = environment.apiRoot;

  processing = false;

  private readonly destroy$ = new Subject<void>();

  private cart: Cart;

  readonly image = img;

  constructor(
    private authService: AuthService,
    private cartService: CartService,
    private productViewModelStore: ProductViewModelStore,
  ) {}

  ngOnInit(): void {
    this._activeVariant = this.product.activeVariant;

    this.quantityOptions = this.product.model.sellable === 'required'
      ? range(1, 1)
      : range(1, 10);

    this.cartService.cart$.pipe(takeUntil(this.destroy$)).subscribe(cart => {
      this.cart = cart;
      this.updateVariant();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addToCart() {
    if (this.productAdded || !this.product.canBuy) {
      return;
    }
    this.processing = true;
    this.cartService.updateItem(this.activeVariant.id, this.quantity)
      .pipe(
        finalize(() => this.processing = false)
      )
      .subscribe(result => {
      });
  }

  updateCart() {
    this.processing = true;
    this.cartService.updateItem(this.activeVariant.id, this.quantity)
      .pipe(
        finalize(() => this.processing = false)
      )
      .subscribe(result => {
      });
  }

  private updateVariant() {
    this.productAdded = !!this.product.cartItem;

    const { options, quantity } = calculateLimitedQuantityWithOptions(
      this.cart,
      this.authService.user,
      this.product.model.sellable,
      this.product.cartItem,
      this.activeVariant.discountedPrice);
    this.quantityOptions = options;
    this._quantity = quantity;
  }
}
