<form (ngSubmit)="submit()">
  <div class="form-input">
    <label for="name">Imię*</label>
    <input [(ngModel)]="name" name="name" id="name">
    <div class="form-input-error" [style.opacity]="nameError ? 1 : 0">Imię jest wymagane</div>
  </div>
  <div class="form-input">
    <label for="surname">Nazwisko*</label>
    <input [(ngModel)]="surname" name="surname" id="surname">
    <div class="form-input-error" [style.opacity]="surnameError ? 1 : 0">Nazwisko jest wymagane</div>
  </div>
  <div class="form-input">
    <label for="email">E-mail*</label>
    <input [(ngModel)]="email" name="email" id="email">
    <div class="form-input-error" [style.opacity]="emailError || userAlreadyExists ? 1 : 0">
<!--      <ng-container *ngIf="userAlreadyExists && email">-->
<!--        E-mail jest już zarejestrowany-->
<!--      </ng-container>-->
<!--      <ng-container *ngIf="!email && !userAlreadyExists">-->
<!--        E-mail jest wymagany-->
<!--      </ng-container>-->
<!--      <ng-container *ngIf="email && !userAlreadyExists">-->
<!--        Niepoprawny e-mail-->
<!--      </ng-container>-->
      {{ getEmailError(email) }}
    </div>
  </div>
  <div class="form-input">
    <label for="phone">Numer telefonu*</label>
    <input [(ngModel)]="phone" name="phone" id="phone">
    <div class="form-input-error" [style.opacity]="phoneError ? 1 : 0">
      <ng-container *ngIf="!phone else invalid_phone">
        Numer telefonu jest wymagany
      </ng-container>
      <ng-template #invalid_phone>
        Niepoprawny numer telefonu
      </ng-template>
    </div>
  </div>
  <div class="form-input">
    <label for="password">Hasło*</label>
    <input type="password" [(ngModel)]="password" name="password" id="password">
    <div class="form-input-error" [style.opacity]="passwordError ? 1 : 0">
      <ng-container *ngIf="!password else invalid_password">
        Hasło jest wymagane
      </ng-container>
      <ng-template #invalid_password>
        Hasło musi mieć min. 8 znaków, w tym jedną cyfrę
      </ng-template>
    </div>
  </div>

  <div class="form-title">Zgody</div>

  <div class="consents">
    <mat-checkbox [(ngModel)]="allConsents" class="all-consents-checkbox" name="allConsents" [disableRipple]="true">
      Zaakceptuj wszystkie zgody
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="marketingConsent" name="offerConsent" [disableRipple]="true">
      Wyrażam zgodę na otrzymywanie informacji handlowych Fibar Group S.A.
      <div class="smaller-text">
        Wyrażam zgodę na przetwarzanie przez Fibar Group S.A. moich danych osobowych (imię, nazwisko, e-mail)
        w celu otrzymywania informacji handlowych drogą elektroniczną.
      </div>
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="orderConsent" name="secretConsent" [disableRipple]="true">
      Akceptuję regulamin sklepu, regulamin usług oraz politykę prywatności*
    </mat-checkbox>
    <div class="consents-help">* zgody obowiązkowe</div>
    <div class="error" [style.opacity]="consentError && !allConsents ? 1 : 0">
      <div class="message">Zaznacz wymagane zgody</div>
    </div>
  </div>

  <app-button type="submit" [disabled]="!enableSubmit || processing" size="small">
    ZAŁÓŻ KONTO
  </app-button>
</form>
