<!--<app-pko-fibaro-banner type="small" title="Konto korporacyjne"></app-pko-fibaro-banner>-->

<div class="card white" style="margin: 60px;">
  <div class="title">Zaloguj się</div>

  <div class="body">
    Numer polisy jest powiązany z kontem użytkownika.
  </div>

  <app-sign-in-form [processing]="processing" [unauthorizedError]="unauthorizedError" (submitForm)="signIn($event)"></app-sign-in-form>
</div>

<app-help-info></app-help-info>
