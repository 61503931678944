<ng-container *ngIf="cart$ | async; let cart">
  <div class="summary">
    <div class="label">Razem:</div>
    <div class="total-prices">
      <div class="original">
        {{ cart.totalPrice | pln }}
      </div>
      <div class="discount">
        {{ cart.totalDiscountedPrice | pln }}
      </div>
    </div>
  </div>

  <app-button *ngIf="vm" [disabled]="!cart || vm.additional.length === 0" (click)="goToNext()" size="small">
    {{ label || 'Dalej' }} ›
  </app-button>
  <a *ngIf="previousUrl" [routerLink]="previousUrl" class="nav-back-btn">Wstecz</a>
</ng-container>
