<div class="cards">
    <div class="card">
        <div class="title"> 
         Dziękujemy za wybór urządzeń FIBARO! 
        </div>
        <div class="content"> 
            <div class="order-info">
                Numer twojego zamówienia to: <p class="order-no">{{orderNumber}}</p> 
            </div>
            Potwierdzenie zakupu oraz dalsze kroki dotyczące instalacji  wysłaliśmy na podany adres e-mail. 
        </div>
    </div>
    <div class="card white call-card">
        <div class="title"> 
            Chcesz dokupić instalację? 
        </div>
        <div class="subtitle">Zadzwoń</div>
        <div class="telephone-wrapper">
            <img src="/assets/phone@2x.svg" alt="Zadzwoń">
            <a href="tel:520123456">520 123 456</a>
        </div>
    </div>
</div>

<app-help-info></app-help-info>