import { Component, OnInit } from '@angular/core';
import { ProductCategoryService } from '../../../shared/services/product-category.service';
import { ProductService } from '../../../shared/services/product.service';
import { Observable } from 'rxjs';
import { Product } from '../../../shared/models/product';
import { ProductViewModel, ProductViewModelStore } from '../../../shared/services/product-view-model.store';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

  products$: Observable<ProductViewModel[]>;

  constructor(private readonly productCategoryService: ProductCategoryService,
              private readonly productService: ProductService,
              private readonly productViewModelStore: ProductViewModelStore) {
    this.products$ = this.productViewModelStore.products$;
  }

  ngOnInit(): void {
    this.productViewModelStore.load();
  }
}
