import { Component, Input, OnInit } from '@angular/core';
import { ProductDetailsViewModel } from '../../../../shared/services/product-details-view-model.store';

@Component({
  selector: 'app-product-details-description',
  templateUrl: './product-details-description.component.html',
  styleUrls: ['./product-details-description.component.scss']
})
export class ProductDetailsDescriptionComponent implements OnInit {

  @Input() productDetails: ProductDetailsViewModel;

  constructor() { }

  ngOnInit(): void {
  }

}
