<div class="card white user-data-card">

  <div class="title">
    Edytuj dane
  </div>
  <div class="form-container">
    <app-user-data-form [user]="user$ | async" [processing]="processing" (submitForm)="submit($event)"></app-user-data-form>
  </div>
  
</div>
  
<app-help-info></app-help-info>
