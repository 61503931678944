import { Injectable } from '@angular/core';
import { Product } from '../models/product';
import { BehaviorSubject, combineLatest, merge, Observable } from 'rxjs';
import { CartItem, CartService } from './cart.service';
import { ProductService } from './product.service';
import { filter, map } from 'rxjs/operators';
import { ProductVariant } from '../models/product-variant';
import { slugFrom } from '../utils/slugFrom';

export interface ProductViewModel {
  model: Product;
  canBuy: boolean;
  activeVariant: ProductVariant;
  cartItem: CartItem;
  slug: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProductViewModelStore {
  private _products$ = new BehaviorSubject<ProductViewModel[]>(undefined);
  readonly products$: Observable<ProductViewModel[]> = this._products$.asObservable();
  get products() { return this._products$.getValue(); }

  constructor(
    private readonly cartService: CartService,
    private readonly productService: ProductService
  ) {
    this.init();
  }

  private init() {
    combineLatest([
      this.cartService.cart$,
      this.productService.products$
    ])
    .pipe(
      filter(tuple => tuple.every(x => !!x)),
      map(([cart, products]) => products.map(product => ({
        model: product,
        canBuy: product.requiredProducts.length === 0
          || cart.items.some(x => product.requiredProducts.includes(x.productId)),
        activeVariant: this.getActiveVariant(product),
        cartItem: cart.items.find(x => x.productId === product.id),
        slug: slugFrom(product.title)
      })))
    )
    .subscribe(products => {
      this._products$.next(products);
    });
  }

  load() {
    merge(
      this.cartService.loadCart(false),
      this.productService.loadProducts(false),
    ).subscribe();
  }

  activateVariant(variant: ProductVariant): void {
    const updated = this._products$.getValue().map(product => {
      if (product.model.variants.some(x => x.id === variant.id)) {
        return {
          ...product,
          activeVariant: variant
        };
      }
      return product;
    });
    this._products$.next(updated);
  }

  private getActiveVariant(product: Product): ProductVariant {
    const defaultVariant = product.variants[0];
    if (!this.products) {
      return defaultVariant;
    }
    const existingProduct = this.products.find(x => x.model.id === product.id);
    const activeVariant = product.variants.find(x => x.id === existingProduct.activeVariant.id);
    return activeVariant || defaultVariant;
  }
}
