<a href="/" class="logo">
  <img src="/assets/fibaro_logo.svg" alt="Fibaro" />
</a>

<div class="menus">
  <a [routerLink]="'koszyk'" *ngIf="authService.user" class="menu">
    <img src="/assets/icons/cart.svg" alt="Koszyk"/>
    <div>Koszyk</div>

    <ng-container *ngIf="(cart$ | async); let cart">
      <div class="cart-items-count" [style.opacity]="cart.items.length > 0 ? 1 : 0">{{ cart.items.length }}</div>
    </ng-container>
  </a>

  <div class="menu dropdown account-menu">
    <img src="/assets/icons/account@2x.svg" alt="Konto"/>
    <div>Konto</div>

    <div class="menu-content">
      <ng-container *ngIf="authService.user$ | async; let user else not_logged">
        <div class="nav item" style="margin-top: 20px">

          <a *ngIf="!authService.insuranceOnlySession" [routerLink]="'/twoje-zakupy'" [routerLinkActive]="'active'">Twoje zakupy</a>
          <a *ngIf="!authService.insuranceOnlySession" [routerLink]="'/twoje-konto'" [routerLinkActive]="'active'">Twoje konto</a>
          <a (click)="signOut()">Wyloguj</a>

        </div>
      </ng-container>
      <ng-template #not_logged>
        <div class="item">
          <div class="title">
            Mam ubezpieczenie
          </div>
          <a [routerLink]="'/weryfikacja'" class="btn small">ZALOGUJ SIĘ</a>
        </div>
        <div class="item">
          <div class="title">
            Konto korporacyjne
          </div>
          <a [routerLink]="'/konto-korporacyjne/rejestracja'" class="btn secondary small">ZAŁÓŻ KONTO</a>
          <a [routerLink]="'/konto-korporacyjne/logowanie'" class="no-account-link">Zaloguj się</a>
        </div>
      </ng-template>
    </div>
  </div>
</div>
