<form (ngSubmit)="submit()">

  <div class="form-input">
    <label for="password">Nowe hasło</label>
    <input [(ngModel)]="password" name="password" id="password" type="password">
    <div class="form-input-info" style="bottom: -30px;">
      Użyj minimum 8 znaków, w tym przynajmniej jedną cyfrę
    </div>
  </div>

  <div class="form-input">
    <label for="repeatedPassword">Powtórz hasło</label>
    <input [(ngModel)]="repeatedPassword" name="repeatedPassword" id="repeatedPassword" type="password">
    <div class="form-input-error" [style.opacity]="repeatedPasswordError ? 1 : 0">
      Wpisz poprawne hasło
    </div>
  </div>

  <app-button type="submit" [disabled]="!enableSubmit || processing" [processing]="processing" size="small">
    RESETUJ HASŁO
  </app-button>
</form>
