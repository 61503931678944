<!--<p>-->
<!--  Bezprzewodowy czujnik dymu i temperatury, który błyskawicznie zareaguje i ochroni Twój dom przed zadymieniem i pożarem.-->
<!--</p>-->
<!--<p>-->
<!--  <b>Najważniejsze cechy:</b>-->
<!--</p>-->
<!--<ul>-->
<!--  <li>-->
<!--    wykrywa nawet małe ilości dymu i-->
<!--    gwałtowne zmiany temperatury,-->
<!--  </li>-->
<!--  <li>-->
<!--    informuje o zagrożeniu dźwiękiem,-->
<!--    miganiem diody LED oraz powiadomieniem-->
<!--    na telefon,-->
<!--  </li>-->
<!--  <li>-->
<!--    jest zasilany bateryjnie,-->
<!--  </li>-->
<!--  <li>-->
<!--    powinien być umieszczony na suficie –-->
<!--    wszędzie tam, gdzie istnieje ryzyko pożaru.-->
<!--  </li>-->
<!--</ul>-->
<!--<p>-->
<!--  <b>Zobacz gdzie umieścić czujnik:</b>-->
<!--</p>-->


<!--{{ productDetails.model.description | markdown }}-->
<div [innerHTML]="productDetails.model.description | markdown"></div>

<!--<div style="border: 1px solid black; width: 100%; height: 330px"></div>-->
