import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { ProductDetailsViewModel, ProductDetailsViewModelStore } from '../../../shared/services/product-details-view-model.store';
import { img } from '../../../shared/utils/img';
import { calculateLimitedQuantityWithOptions } from '../../../shared/utils/limited-quantity';
import { ProductDetails } from '../../../shared/models/product-details';
import { Cart, CartService } from '../../../shared/services/cart.service';
import { ProductVariant } from '../../../shared/models/product-variant';
import { range } from '../../../shared/utils/range';
import { AuthService } from '../../../shared/auth/services/auth.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit, OnDestroy {

  readonly productDetails$: Observable<ProductDetailsViewModel>;

  productDetails: ProductDetailsViewModel;
  cart: Cart;

  get activeVariant() { return this._activeVariant; }
  set activeVariant(value) {
    if (this._activeVariant === value) {
      return;
    }
    this._activeVariant = value;
    this.updateVariant();
    this.productDetailsViewModelStore.activateVariant(value);
  }
  private _activeVariant: ProductVariant;

  get quantity() {
    return this._quantity;
  }
  set quantity(value) {
    if (this._quantity === value) {
      return;
    }

    this._quantity = value;

    if (this.productAdded) {
      this.updateCart();
    }
  }
  private _quantity = 1;

  quantityOptions: number[];

  productAdded = false;

  processing = false;

  activeTab = 0;

  private readonly destroy$ = new Subject<void>();

  get thumbnailUri() {
    return this.productDetailsViewModelStore.productDetails.activeVariant.imageUris[0];
  }

  get galleryUris() {
    const uris = this.productDetailsViewModelStore.productDetails.activeVariant.imageUris;
    console.log('gallery', uris.slice(1, uris.length - 1));

    return uris.slice(1, uris.length);
  }

  get desktopInstallationImageUri() {
    const uris = this.productDetails.model.installationImageUris;
    if (!uris) {
      return undefined;
    }
    return uris.length === 1 ? uris[0] : uris[1];
  }

  get mobileInstallationImageUri() {
    const uris = this.productDetails.model.installationImageUris;
    if (!uris) {
      return undefined;
    }
    return uris[0];
  }

  readonly image = img;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private cartService: CartService,
    private productDetailsViewModelStore: ProductDetailsViewModelStore
  ) {
    this.productDetails$ = this.productDetailsViewModelStore.productDetails$;
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.productDetailsViewModelStore.load(params.get('slug'));
    });
    this.productDetailsViewModelStore.productDetails$.pipe(filter(x => !!x), takeUntil(this.destroy$)).subscribe(productDetails => {
      this.productDetails = productDetails;

      this._activeVariant = this.productDetails.activeVariant;

      this.quantityOptions = this.productDetails.model.sellable === 'required'
        ? range(1, 1)
        : range(1, 10);
    });
    this.cartService.cart$.pipe(takeUntil(this.destroy$)).subscribe(cart => {
      this.cart = cart;
      this.updateVariant();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addToCart(): void {

  }

  updateCart() {
    this.processing = true;
    this.cartService.updateItem(this.activeVariant.id, this.quantity)
      .pipe(
        finalize(() => this.processing = false)
      )
      .subscribe(result => {
      });
  }

  private updateVariant() {
    if (!this.productDetails) {
      return;
    }

    this.productAdded = !!this.productDetails.cartItem;

    const { options, quantity } = calculateLimitedQuantityWithOptions(
      this.cart,
      this.authService.user,
      this.productDetails.model.sellable,
      this.productDetails.cartItem,
      this.activeVariant.discountedPrice);
    this.quantityOptions = options;
    this._quantity = quantity;
  }
}
