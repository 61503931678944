import { Component, Input, OnInit } from '@angular/core';
import { CartItem } from '../../services/cart.service';
import { CartItemViewModel } from '../../services/cart-view-model.store';
import { img } from '../../utils/img';

@Component({
  selector: 'app-order-summary-item',
  templateUrl: './order-summary-item.component.html',
  styleUrls: ['./order-summary-item.component.scss']
})
export class OrderSummaryItemComponent implements OnInit {

  @Input() vm: CartItemViewModel;

  readonly image = img;

  constructor() { }

  ngOnInit(): void {
  }

}
