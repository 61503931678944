import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/services/auth.service';
import { Cart, CartItem, CartService } from '../../services/cart.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  accountMenuOpened = false;

  readonly cart$: Observable<Cart>;

  constructor(
    public readonly authService: AuthService,
    private readonly cartService: CartService,
    private readonly router: Router
  ) {
    this.cart$ = this.cartService.cart$;
  }

  ngOnInit(): void {
    this.authService.user$.subscribe(user => {
      if (user) {
        this.cartService.loadCart().subscribe();
      } else {
        this.cartService.reset();
      }
    });
  }

  signOut() {
    this.authService.signOut().subscribe(() => {
      this.router.navigate(['/weryfikacja']);
    });
  }
}
