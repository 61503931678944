import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProductCategory } from '../models/product-category';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductCategoryService {
  private readonly _categories$ = new BehaviorSubject<ProductCategory[]>(undefined);
  readonly categories$ = this._categories$.asObservable();

  constructor(private readonly http: HttpClient) {}

  loadCategories(): Observable<ProductCategory[]> {
    const url = `${environment.apiRoot}/productCategories`;
    this._categories$.next(undefined);
    return this.http.get<ProductCategory[]>(url, { withCredentials: true })
      .pipe(
        tap(categories => this._categories$.next(categories))
      );
  }
}
