import { Component, Input, OnInit } from '@angular/core';
import { ProductDetailsViewModel } from '../../../../shared/services/product-details-view-model.store';

type Spec = [string, string[]];

@Component({
  selector: 'app-product-details-specs',
  templateUrl: './product-details-specs.component.html',
  styleUrls: ['./product-details-specs.component.scss']
})
export class ProductDetailsSpecsComponent implements OnInit {

  @Input() productDetails: ProductDetailsViewModel;

  specs: Spec[];

  constructor() { }

  ngOnInit(): void {
    this.specs = this.parse(this.productDetails.model.specs);
  }

  parse(specs: string): Spec[] {
    if (!specs) {
      return [];
    }
    const rows = specs.split('\n\n');
    return rows.map(row => {
      const split = row.split(':\n').filter(x => !!x);
      if (split.length === 0) {
        return undefined;
      }
      return [split[0], split[1].split('\n')];
    }).filter(x => !!x) as Spec[];
  }
}
