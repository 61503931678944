import { Component, OnInit } from '@angular/core';
import { PolicyNoForm } from '../../components/policy-no-form/policy-no-form.component';
import { AuthService } from '../../../shared/auth/services/auth.service';
import { Router } from '@angular/router';
import { catchError, finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY, throwError } from 'rxjs';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {

  invalidPolicyNoError = false;
  processing = false;

  constructor(private authService: AuthService,
              private router: Router) {}

  ngOnInit(): void {
  }

  submit(form: PolicyNoForm) {
    if (this.processing) {
      return;
    }
    this.processing = true;
    this.authService.signInPolicyNo(form)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404) {
            this.invalidPolicyNoError = true;
            return EMPTY;
          }
          return throwError(error);
        }),
        finalize(() => this.processing = false)
      )
      .subscribe(user => {
        if (!user.isNewInsurance) {
          this.router.navigate(['/konto-klienta/logowanie']);
        } else {
          this.goToProductList();
        }
      });
  }

  goToProductList() {
    this.router.navigate(['/']);
  }
}
