<form (ngSubmit)="submit()">

    <div class="form-input">
      <label for="name">Imię*</label>
      <input [(ngModel)]="name" name="name" id="name">
      <div class="form-input-error" [style.opacity]="nameError ? 1 : 0">Imię jest wymagane</div>
    </div>

    <div class="form-input">
      <label for="surname">Nazwisko*</label>
      <input [(ngModel)]="surname" name="surname" id="surname">
      <div class="form-input-error" [style.opacity]="surnameError ? 1 : 0">Nazwisko jest wymagane</div>
    </div>

    <div class="form-input">
      <label for="email">E-mail*</label>
      <input [(ngModel)]="email" name="email" id="email">
      <div class="form-input-error" [style.opacity]="emailError ? 1 : 0">
        <ng-container *ngIf="!email else invalid_email">
          E-mail jest wymagany
        </ng-container>
        <ng-template #invalid_email>
          Niepoprawny e-mail
        </ng-template>
      </div>
    </div>

    <div class="form-input">
      <label for="phone">Numer telefonu*</label>
      <input [(ngModel)]="phone" name="phone" id="phone">
      <div class="form-input-error" [style.opacity]="phoneError ? 1 : 0">
        <ng-container *ngIf="!phone else invalid_phone">
          Numer telefonu jest wymagany
        </ng-container>
        <ng-template #invalid_phone>
          Niepoprawny numer telefonu
        </ng-template>
      </div>
    </div>

    <div class="form-input">
        <label for="password">Hasło*</label>
        <input type="password" [(ngModel)]="password" name="password" id="password">
        <div class="form-input-error" [style.opacity]="passwordError ? 1 : 0">
          Niepoprawne hasło
        </div>
      </div>

<!--    <div class="form-title">Zgody</div>-->
<!--  -->
<!--    <div class="form-input">-->
<!--        <mat-checkbox name="fibaroMarketingConsent">-->
<!--            <strong>Zaakceptuj wszystkie zgody</strong>-->
<!--        </mat-checkbox>-->
<!--    </div>-->
<!--    -->
<!--    <div class="form-input">-->
<!--        <mat-checkbox name="fibaroMarketingConsent">-->
<!--          Akceptuję <a href="#">regulamin sklepu</a>-->
<!--          <br> <a href="#">regulamin usług</a>-->
<!--          <br> oraz <a href="#">politykę prywatności</a>*  -->
<!--        </mat-checkbox>-->
<!--    </div>-->

<!--    <div class="form-input">-->
<!--        <mat-checkbox name="fibaroMarketingConsent">-->
<!--          Akceptuję <a href="#">zgody marketingowe</a> FIBARO-->
<!--        </mat-checkbox>-->
<!--    </div>-->
<!--    <div class="subtitle">* zgody obowiązkowe</div>-->

   <app-button type="submit" size="small">
    ZAREJESTRUJ SIĘ ›
   </app-button>
  </form>
