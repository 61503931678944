import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { nonEmptyStr, phoneNumber, validate, Validator, email } from '../../../shared/utils/validate';
import { Cart } from '../../../shared/services/cart.service';
import { AuthService } from '../../../shared/auth/services/auth.service';

export interface DeliveryForm {
  readonly name: string;
  readonly surname: string;
  readonly street: string;
  readonly houseNo: string;
  readonly flatNo: string;
  readonly postalCode: string;
  readonly city: string;
  readonly email: string;
  readonly phone: string;
  readonly comment: string;
  readonly orderConsent: boolean;
  readonly marketingConsent: boolean;
}

@Component({
  selector: 'app-delivery-form',
  templateUrl: './delivery-form.component.html',
  styleUrls: ['./delivery-form.component.scss']
})
export class DeliveryFormComponent implements OnInit {
  @Input() cart: Cart;
  @Input() processing: boolean;

  get name() { return this._name; }
  set name(value) {
    this.setAndValidateInput('name', value, validate(nonEmptyStr));
  }
  private _name = '';

  get surname() { return this._surname; }
  set surname(value) {
    this.setAndValidateInput('surname', value, validate(nonEmptyStr));
  }
  private _surname = '';

  get street() { return this._street; }
  set street(value) {
    this.setAndValidateInput('street', value, validate(nonEmptyStr));
  }
  private _street = '';

  get houseNumber() { return this._houseNumber; }
  set houseNumber(value) {
    this.setAndValidateInput('houseNumber', value, validate(nonEmptyStr));
  }
  private _houseNumber = '';

  get flatNumber() { return this._flatNumber; }
  set flatNumber(value) {
    this.setAndValidateInput('flatNumber', value, validate());
  }
  private _flatNumber = '';

  get postalCode() { return this._postalCode; }
  set postalCode(value) {
    this.setAndValidateInput('postalCode', value, validate(nonEmptyStr));
  }
  private _postalCode = '';

  get city() { return this._city; }
  set city(value) {
    this.setAndValidateInput('city', value, validate(nonEmptyStr));
  }
  private _city = '';

  get email() { return this._email; }
  set email(value) {
    this.setAndValidateInput('email', value, validate(nonEmptyStr, email));
  }
  private _email = '';

  get phone() { return this._phone; }
  set phone(value) {
    this.setAndValidateInput('phone', value, validate(nonEmptyStr, phoneNumber));
  }
  private _phone = '';

  get comment() { return this._comment; }
  set comment(value) {
    this.setAndValidateInput('comment', value, validate());
  }
  private _comment = '';

  get allConsents() {
    return this.marketingConsent && this.orderConsent;
  }
  set allConsents(value) {
    this.marketingConsent = this.orderConsent = value;
  }

  get marketingConsent() { return this._marketingConsent; }
  set marketingConsent(value) {
    this._marketingConsent = value;
  }
  private _marketingConsent = false;

  get orderConsent() { return this._orderConsent; }
  set orderConsent(value) {
    this._orderConsent = value;
  }
  private _orderConsent = false;

  nameError = false;
  surnameError = false;
  streetError = false;
  houseNumberError = false;
  postalCodeError = false;
  cityError = false;
  emailError = false;
  phoneError = false;
  consentError = false;

  @Output() readonly submitForm = new EventEmitter<DeliveryForm>();

  get enableSubmit() {
    return !(
      this.nameError
      || this.surnameError
      || this.streetError
      || this.houseNumberError
      || this.postalCodeError
      || this.cityError
      || this.emailError
      || this.phoneError
    );
  }

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    const user = this.authService.user;
    if (!this.cart.delivery) {
      this.name = user.name;
      this.surname = user.surname;
      this.email = user.email;
      this.phone = user.phone;
      return;
    }
    this.name = this.cart.delivery.name || user.name;
    this.surname = this.cart.delivery.surname || user.surname;
    this.street = this.cart.delivery.street || user.street;
    this.houseNumber = this.cart.delivery.houseNo || user.houseNo;
    this.flatNumber = this.cart.delivery.flatNo || user.flatNo;
    this.postalCode = this.cart.delivery.postalCode || user.postalCode;
    this.city = this.cart.delivery.city || user.city;
    this.email = this.cart.delivery.email || user.email;
    this.phone = this.cart.delivery.phone || user.phone;
    this.comment = this.cart.delivery.comment;
    this.marketingConsent = user.fibaroMarketingConsent;
    this.orderConsent = this.cart.delivery.orderConsent;
  }

  submit() {
    if (!this.validateInputs()) {
      return;
    }
    if (!this.orderConsent) {
      this.consentError = true;
    }
    if (!this.enableSubmit) {
      return;
    }
    this.submitForm.emit({
      name: this.name,
      surname: this.surname,
      street: this.street,
      city: this.city,
      flatNo: this.flatNumber,
      houseNo: this.houseNumber,
      postalCode: this.postalCode,
      email: this.email,
      phone: this.phone,
      comment: this.comment,
      orderConsent: this.orderConsent,
      marketingConsent: this.marketingConsent
    });
  }

  private validateInputs() {
    const inputValidations: [string, Validator][] = [
      ['name', validate(nonEmptyStr)],
      ['surname', validate(nonEmptyStr)],
      ['street', validate(nonEmptyStr)],
      ['houseNumber', validate(nonEmptyStr)],
      ['postalCode', validate(nonEmptyStr)],
      ['city', validate(nonEmptyStr)],
      ['email', validate(nonEmptyStr, email)],
      ['phone', validate(nonEmptyStr, phoneNumber)],
    ];

    let valid = true;

    for (const [name, validator] of inputValidations) {
      const validInput = this.validateInput(name, this[name], validator);
      valid = valid && validInput;
    }

    return valid;

    // return inputValidations
    //   .reduce((valid, [name, validator]) => valid && this.validateInput(name, this[name], validator), true);
  }

  private setAndValidateInput(name: string, value: any, validator: Validator): boolean {
    this['_' + name] = value;
    return this.validateInput(name, value, validator);
  }

  private validateInput(name: string, value: any, validator: Validator): boolean {
    const valid = validator(value);
    this[name + 'Error'] = !valid;
    return valid;
  }
}
