import { Component, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/shared/auth/services/auth.service';
import { UserDataForm, UserDataFormComponent } from '../../components/user-data-form/user-data-form.component';
import { User } from 'src/app/shared/models/user';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.scss']
})
export class UserDataComponent {

  @ViewChild(UserDataFormComponent) userDataForm: UserDataFormComponent;

  processing = false;

  readonly user$: Observable<User>

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) {
    this.user$ = this.authService.user$;
  }

  submit(form: UserDataForm) {
    this.processing = true;
    this.authService.updateUserData(form).pipe(finalize(() => this.processing = false)).subscribe(() => {
      this.router.navigate(['/twoje-konto']);
    })
  }
}
