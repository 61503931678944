<ng-container *ngIf="specs.length > 0">
  <table cellspacing="0">
    <tr *ngFor="let spec of specs">
      <td>
        {{ spec[0] }}
      </td>
      <td>
        <div *ngFor="let value of spec[1]">
          {{ value }}
        </div>
      </td>
    </tr>
  </table>
</ng-container>

<ng-container *ngIf="specs.length === 0">
  <table cellspacing="0">
    <tr>
      <td>
        Typ baterii
      </td>
      <td>
        CR123A 3.0V DC (w zestawie)
      </td>
    </tr>
    <tr>
      <td>
        Temperatura pracy
      </td>
      <td>
        0 – 55°C
      </td>
    </tr>
    <tr>
      <td>
        Zakres pomiarowy sensora temperatury
      </td>
      <td>
        -20 – 100°C
      </td>
    </tr>
    <tr>
      <td>
        Typ baterii
      </td>
      <td>
        CR123A 3.0V DC (w zestawie)
      </td>
    </tr>
    <tr>
      <td>
        Temperatura pracy
      </td>
      <td>
        0 – 55°C
      </td>
    </tr>
    <tr>
      <td>
        Zakres pomiarowy sensora temperatury
      </td>
      <td>
        -20 – 100°C
      </td>
    </tr>
    <tr>
      <td>
        Typ baterii
      </td>
      <td>
        CR123A 3.0V DC (w zestawie)
      </td>
    </tr>
    <tr>
      <td>
        Temperatura pracy
      </td>
      <td>
        0 – 55°C
      </td>
    </tr>
    <tr>
      <td>
        Zakres pomiarowy sensora temperatury
      </td>
      <td>
        -20 – 100°C
      </td>
    </tr>
  </table>
</ng-container>

