import { Component, OnInit } from '@angular/core';
import { SignUpForm } from '../../components/sign-up-form/sign-up-form.component';
import { AuthService } from '../../../shared/auth/services/auth.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  processing = false;
  signedUp = false;
  userAlreadyExists = false;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {}

  signUp(form: SignUpForm) {
    this.processing = true;
    this.authService.signUp(form, 'bank_employee').subscribe(result => {
      if (result.userExistsError) {
        this.userAlreadyExists = true;
      } else {
        this.processing = false;
        this.signedUp = true;
      }
    });
  }
}
