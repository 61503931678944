import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../shared/auth/services/auth.service';
import { SignInForm } from '../../components/sign-in-form/sign-in-form.component';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  processing = false;
  unauthorizedError = false;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
  }

  signIn(form: SignInForm) {
    this.processing = true;
    this.unauthorizedError = false;
    this.authService.signIn(form.email, form.password).pipe(finalize(() => this.processing = false)).subscribe(result => {
      if (result.ok) {
        this.router.navigate(['/']);
      } else {
        this.unauthorizedError = true;
      }
    });
  }
}
