import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../shared/auth/services/auth.service';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-activate-user',
  templateUrl: './activate-user.component.html',
  styleUrls: ['./activate-user.component.scss']
})
export class ActivateUserComponent implements OnInit {

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap
      .pipe(
        map(params => ({ email: params.get('email'), activationCode: params.get('activationCode') })),
        switchMap(activation => this.authService.activateUser(activation)),
        switchMap(() => this.authService.autoSignIn())
      )
      .subscribe(user => {
        if (user.role === 'bank_employee') {
          this.router.navigate(['/']);
        } else if (user.role === 'policy_owner') {
          this.router.navigate(['/dostawa']);
        } else {
          throw new Error(`Not implemented for role ${user.role}`);
        }
      });
  }
}
