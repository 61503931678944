import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ProductListComponent } from './pages/product-list/product-list.component';
import { VerificationComponent } from './pages/verification/verification.component';
import { PolicyNoFormComponent } from './components/policy-no-form/policy-no-form.component';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SignUpFormComponent } from './components/sign-up-form/sign-up-form.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { SignInFormComponent } from './components/sign-in-form/sign-in-form.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { RouterModule } from '@angular/router';
import { ActivateUserComponent } from './pages/activate-user/activate-user.component';
import { CartComponent } from './pages/cart/cart.component';
import { InstallationMethodComponent } from './pages/installation-method/installation-method.component';
import { DeliveryComponent } from './pages/delivery/delivery.component';
import { DeliveryFormComponent } from './components/delivery-form/delivery-form.component';
import { InstallationAddressComponent } from './pages/installation-address/installation-address.component';
import { InstallationAddressFormComponent } from './components/installation-address-form/installation-address-form.component';
import { OrderSummaryComponent } from './pages/order-summary/order-summary.component';
import { UserSettingsComponent } from './pages/user-settings/user-settings.component';
import { PaymentComponent } from './pages/payment/payment/payment.component';
import { OrderConfirmationComponent } from './pages/order-confirmation/order-confirmation.component';
import { UserOrdersComponent } from './pages/user-orders/user-orders.component';
import { UserDataComponent } from './pages/user-data/user-data.component';
import { UserDataFormComponent } from './components/user-data-form/user-data-form.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ResetPasswordFormComponent } from './components/reset-password-form/reset-password-form.component';
import { ChangePasswordFormComponent } from './components/change-password-form/change-password-form.component';
import { UserDeliveryComponent } from './pages/user-delivery/user-delivery.component';
import { UserDeliveryFormComponent } from './components/user-delivery-form/user-delivery-form.component';
import { UserAgreementsComponent } from './pages/user-agreements/user-agreements.component';
import { UserAgreementsFormComponent } from './components/user-agreements-form/user-agreements-form.component';
import { CreateAccountComponent } from './pages/create-account/create-account.component';
import { CreateAccountFormComponent } from './components/create-account-form/create-account-form.component';
import { OrderAccountComponent } from './pages/order-account/order-account.component';
import { SignUpWithInsuranceComponent } from './pages/sign-up-with-insurance/sign-up-with-insurance.component';
import { SignUpWithInsuranceFormComponent } from './components/sign-up-with-insurance-form/sign-up-with-insurance-form.component';
import { SignInWithInsuranceComponent } from './pages/sign-in-with-insurance/sign-in-with-insurance.component';
import { ProductDetailsComponent } from './pages/product-details/product-details.component';
import { ProductDetailsSpecsComponent } from './pages/product-details/product-details-specs/product-details-specs.component';
import { ProductDetailsUseCasesComponent } from './pages/product-details/product-details-use-cases/product-details-use-cases.component';
import { ProductDetailsDescriptionComponent } from './pages/product-details/product-details-description/product-details-description.component';
import { MarkdownModule } from 'ngx-markdown';

const COMPONENTS = [
  ActivateUserComponent,
  CartComponent,
  ChangePasswordComponent,
  ChangePasswordFormComponent,
  DeliveryComponent,
  DeliveryFormComponent,
  InstallationAddressComponent,
  InstallationAddressFormComponent,
  InstallationMethodComponent,
  OrderAccountComponent,
  OrderConfirmationComponent,
  OrderSummaryComponent,
  PaymentComponent,
  PolicyNoFormComponent,
  ProductDetailsComponent,
  ProductDetailsDescriptionComponent,
  ProductDetailsSpecsComponent,
  ProductDetailsUseCasesComponent,
  ProductListComponent,
  ResetPasswordComponent,
  ResetPasswordFormComponent,
  SignInComponent,
  SignInFormComponent,
  SignInWithInsuranceComponent,
  SignUpComponent,
  SignUpFormComponent,
  SignUpWithInsuranceComponent,
  SignUpWithInsuranceFormComponent,
  UserOrdersComponent,
  UserSettingsComponent,
  VerificationComponent,
  UserDataComponent,
  UserDataFormComponent,
  UserDeliveryComponent,
  UserDeliveryFormComponent,
  UserAgreementsComponent,
  UserAgreementsFormComponent,
  CreateAccountComponent,
  CreateAccountFormComponent
];

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    MatCheckboxModule,
    RouterModule,
    MarkdownModule.forRoot()
  ],
  declarations: [
    ...COMPONENTS,
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class ShopModule {}
