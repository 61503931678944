import { ProductVariant } from '../models/product-variant';
import { CartItem, CartService } from './cart.service';
import { ProductDetails } from '../models/product-details';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, merge, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProductDetailsService } from './product-details.service';

export interface ProductDetailsViewModel {
  model: ProductDetails;
  canBuy: boolean;
  activeVariant: ProductVariant;
  cartItem: CartItem;
  slug: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProductDetailsViewModelStore {
  private _productDetails$ = new BehaviorSubject<ProductDetailsViewModel>(undefined);
  readonly productDetails$: Observable<ProductDetailsViewModel> = this._productDetails$.asObservable();
  get productDetails() { return this._productDetails$.getValue(); }

  constructor(
    private readonly cartService: CartService,
    private readonly productDetailsService: ProductDetailsService
  ) {
    this.init();
  }

  private init() {
    combineLatest([
      this.cartService.cart$,
      this.productDetailsService.productDetails$
    ])
      .pipe(
        filter(tuple => tuple.every(x => !!x)),
        map(([cart, productDetails]) => ({
          model: productDetails,
          canBuy: productDetails.requiredProducts.length === 0
            || cart.items.some(x => productDetails.requiredProducts.includes(x.productId)),
          activeVariant: this.getActiveVariant(productDetails),
          cartItem: cart.items.find(x => x.productId === productDetails.productId),
          slug: productDetails.slug
        }))
      )
      .subscribe(productDetails => {
        console.log('next details', productDetails);
        this._productDetails$.next(productDetails);
      });
  }

  load(slug: string) {
    merge(
      this.cartService.loadCart(false),
      this.productDetailsService.loadProductDetails(slug),
    ).subscribe();
  }

  activateVariant(variant: ProductVariant): void {
    const updated = {
      ...this.productDetails,
      activeVariant: variant
    };
    this._productDetails$.next(updated);
  }

  private getActiveVariant(productDetails: ProductDetails): ProductVariant {
    const defaultVariant = productDetails.variants[0];
    if (!this.productDetails) {
      return defaultVariant;
    }
    return this.productDetails.activeVariant || defaultVariant;
  }
}
