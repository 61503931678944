import { Component, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/shared/auth/services/auth.service';
import { UserAgreementsForm, UserAgreementsFormComponent } from '../../components/user-agreements-form/user-agreements-form.component';
import { User } from 'src/app/shared/models/user';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-user-agreements',
  templateUrl: './user-agreements.component.html',
  styleUrls: ['./user-agreements.component.scss']
})
export class UserAgreementsComponent {

  @ViewChild(UserAgreementsFormComponent) userAgreementsForm: UserAgreementsFormComponent;

  processing = false;

  readonly user$: Observable<User>

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) {
    this.user$ = this.authService.user$;
  }

  submit(form: UserAgreementsForm) {
    this.processing = true;
    this.authService.updateUserAgreements(form).pipe(finalize(() => this.processing = false)).subscribe(() => {
      this.router.navigate(['/twoje-konto']);
    })
  }

}
