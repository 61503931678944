import { Injectable } from '@angular/core';
import { Cart, CartItem, CartService } from './cart.service';
import { ProductService } from './product.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Product } from '../models/product';

export interface CartViewModel {
  readonly model: Cart;
  readonly required: CartItemViewModel[];
  readonly additional: CartItemViewModel[];
}

export interface CartItemViewModel {
  readonly item: CartItem;
  readonly product: Product;
  readonly discountPercentage: number;
  readonly totalPrice: number;
  readonly totalDiscountedPrice: number;
}

export class Store<T> {

}

@Injectable({
  providedIn: 'root'
})
export class CartViewModelStore {

  private readonly _vm$ = new BehaviorSubject<CartViewModel>(undefined);
  readonly vm$ = this._vm$.asObservable();

  constructor(
    private readonly cartService: CartService,
    private readonly productService: ProductService
  ) {
    this.init();
  }

  private init() {
    combineLatest([
      this.cartService.cart$,
      this.productService.products$
    ])
      .pipe(
        filter(tuple => tuple.every(x => !!x)),
        map(([cart, products]) => ({
          cart,
          items: cart.items.map(item => ({
            item,
            product: products.find(x => x.id === item.productId),
            discountPercentage: Math.round((1 - item.variant.discountedPrice / item.variant.pricePerUnit) * 100),
            totalPrice: Math.round(item.variant.pricePerUnit * item.quantity * 100) / 100,
            totalDiscountedPrice: Math.round(item.variant.discountedPrice * item.quantity * 100) / 100
          })) as CartItemViewModel[]
        }))
      )
      .subscribe(({ cart, items }) => {
        this._vm$.next({
          model: cart,
          required: items.filter(x => x.item.required),
          additional: items.filter(x => !x.item.required)
        });
      });
  }

  load() {
    this.cartService.loadCart(false).subscribe();
    this.productService.loadProducts(false).subscribe();
  }
}
