import { Component, ViewChild } from '@angular/core';
import { User } from 'src/app/shared/models/user';
import { AuthService } from 'src/app/shared/auth/services/auth.service';
import { AccountForm, CreateAccountFormComponent } from '../../components/create-account-form/create-account-form.component';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SignUpForm } from '../../components/sign-up-form/sign-up-form.component';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent {

  @ViewChild(CreateAccountFormComponent) accountForm: CreateAccountFormComponent;

  processing = false;
  signedUp = false;
  userAlreadyExists = false;

  readonly user$: Observable<User>;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) {
    this.user$ = this.authService.user$;
  }

  submit(form: SignUpForm) {
    this.processing = true;
    this.authService.signUpWithInsurance(form, 'policy_owner').pipe(tap(() => this.processing = false)).subscribe(result => {
      if (result.userExistsError) {
        this.userAlreadyExists = true;
      } else {
        this.signedUp = true;
      }
    });
  }
}
