<div class="description">
  Masz pytania?
<!--  <ng-container *ngIf="!authService.user || authService.user.role !== 'bank_employee'">-->
<!--    <br>-->
<!--    Chcesz sprawdzić, czy Twoja polisa uprawnia do skorzystania z PKO Dom?-->
<!--  </ng-container>-->
</div>
<div class="links">
  <app-icon-with-link [src]="'/assets/question@2x.svg'" [href]="'https://pkoubezpieczenia.pl/PKOID'" alt="Dowiedz się więcej">
    Dowiedz się więcej
  </app-icon-with-link>
  <app-icon-with-link class="phone" [src]="'/assets/phone@2x.svg'" [href]="'tel:+48815356766'" alt="Zadzwoń">
    Zadzwoń
  </app-icon-with-link>
</div>
