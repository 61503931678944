<div class="banner-container">
  <app-pko-fibaro-banner style="margin-bottom: 26px"></app-pko-fibaro-banner>
</div>

<div class="form-container">
  <app-policy-no-form [(invalidPolicyNoError)]="invalidPolicyNoError" [processing]="processing" (submitForm)="submit($event)"></app-policy-no-form>
  <img class="house" src="/assets/house.png" alt="Dom" />
</div>

<app-help-info></app-help-info>
