<div class="header">
  <img class="icon" *ngIf="product.model.sellable !== 'required'" [src]="'/assets/category-icons/' + (product.model.category.slug | category_icon) + '.png'" [alt]="product.model.category.slug" />
  <div class="text">
    {{ product.model.category.name }}
  </div>
</div>
<div class="content">

  <a [routerLink]="['/karta-produktu', product.slug]" class="product-img-link">
    <img [src]="image(thumbnailUri)" class="product-img" [alt]="product.model.title" />
  </a>

  <div *ngIf="product.model.variants && product.model.variants.length > 1" class="color-variants">
    <div *ngFor="let variant of product.model.variants" class="color-variant" [attr.data-color]="variant.color"
         (click)="activeVariant = variant" [class.active]="activeVariant === variant"></div>
  </div>

  <a [routerLink]="['/karta-produktu', product.slug]" class="title">
    {{ product.model.title }}
  </a>

  <app-discounted-price
    [originalPrice]="activeVariant.price"
    [discountedPrice]="activeVariant.discountedPrice">
  </app-discounted-price>

  <div class="actions">
    <mat-select [(value)]="quantity">
      <mat-option *ngFor="let option of quantityOptions" [value]="option">{{ option }}</mat-option>
    </mat-select>
    <app-button class="add-to-cart-btn" size="small" (click)="addToCart()" [secondary]="productAdded"
                [processing]="processing" [disabled]="processing || !product.canBuy">
      {{ productAdded ? 'Dodano' : 'Dodaj do koszyka' }}
    </app-button>
  </div>
</div>
