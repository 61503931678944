<app-step-display [current]="3" [count]="workflowService.totalSteps"></app-step-display>

<div class="full-page-title">Podsumowanie</div>

<div *ngIf="cart$ | async; let cart" class="cards">
  <div class="order-summary-container">
    <div class="card white delivery-card">
      <div class="title">
        <div>Dane odbiorcy</div>
        <a [routerLink]="'/dostawa'">Edytuj ›</a>
      </div>
      <div class="body">
        <div class="credentials">
          <div class="name">Adres dostawy</div>
          <div class="content">
            <div>{{ cart.delivery.name }} {{ cart.delivery.surname }}</div>
            <div>{{ cart.delivery.street }} {{ cart.delivery.houseNo }}{{ (cart.delivery.flatNo ? '/' + cart.delivery.flatNo : '') }}</div>
            <div>{{ cart.delivery.postalCode }} {{ cart.delivery.city }}</div>
          </div>
        </div>
        <div class="credentials">
          <div class="name">Dane kontaktowe</div>
          <div class="content">
            <div>{{ cart.delivery.email }}</div>
            <div>{{ cart.delivery.phone }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="card white order-summary-card">
      <div class="title">
        <div>Podsumowanie zamówienia</div>
      </div>
      <div *ngIf="vm$ | async; let vm" class="body">
        <div class="section">
          <app-order-summary-item *ngFor="let item of vm.required" [vm]="item"></app-order-summary-item>
        </div>
        <div class="section">
          <app-order-summary-item *ngFor="let item of vm.additional" [vm]="item"></app-order-summary-item>
        </div>
        <div class="section">
          <div class="card white installation-card">
            <div class="title">
              {{ (vm.model.installationMethod.type | installation_type).description }}
            </div>
            <div class="body">
              <div class="price-container">
                <div class="price-label">Cena:</div>
                <div class="price original">{{ vm.model.installationPrice | pln }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="card white installation-card">
            <div class="title">
              Bezpłatna dostawa
            </div>
            <div class="body">
              <div class="price-container">
                <div class="price-label">Cena:</div>
                <div class="price original">{{ 0 | pln }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-cart-summary-box [label]="workflowService.totalSteps === 3 ? 'Zapłać' : 'Dalej'"
                        [next]="workflowService.nextUrl" type="long"
                        [previousUrl]="'/dostawa'"></app-cart-summary-box>
</div>

<!--<app-help-info></app-help-info>-->

<app-cart-footer-summary [label]="workflowService.totalSteps === 3 ? 'Zapłać' : 'Dalej'"
                         [nextUrl]="workflowService.nextUrl"
                         [previousUrl]="'/dostawa'"></app-cart-footer-summary>
