import { Component } from '@angular/core';
import { AuthService } from 'src/app/shared/auth/services/auth.service';
import { User } from 'src/app/shared/models/user';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent  {

  user: User;

  constructor (
    private readonly authservice: AuthService
  ) {
    this.authservice.user$.subscribe(user => this.user = user);
  }
}
