import { Pipe, PipeTransform } from '@angular/core';
import { InstallationType } from '../models/installation-type';

@Pipe({
  name: 'installation_type'
})
export class InstallationTypePipe implements PipeTransform {
  transform(value: string, ...args: any[]) {
    switch (value) {
      case 'onsite':
        return {
          title: 'Instalator FIBARO',
          description: 'Instalacja przez certyfikowanego instalatora FIBARO',
          iconUrl: '/assets/icons/ikona_instalator.png'
        };
      case 'remote':
        return {
          title: 'Zdalna konfiguracja',
          description: 'Zdalna konfiguracja online ze wsparciem eksperta FIBARO',
          iconUrl: '/assets/icons/ikona_zdalna konf.png'
        };
      case 'self':
        return {
          title: 'Samodzielna instalacja',
          description: 'Samodzielna instalacja i konfiguracja urządzeń FIBARO',
          iconUrl: '/assets/icons/ikona_sam instalacja.png'
        };
      default:
        throw new Error(`Unknown installation type: ${value}`);
    }
  }
}
