import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pko-fibaro-banner',
  templateUrl: './pko-fibaro-banner.component.html',
  styleUrls: ['./pko-fibaro-banner.component.scss']
})
export class PkoFibaroBannerComponent implements OnInit {

  @Input() title: string;
  @Input() type: 'smart-home' | 'small' = 'smart-home';

  constructor() { }

  ngOnInit(): void {
  }

}
