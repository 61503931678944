<app-step-display [current]="1" [count]="4"></app-step-display>

<div class="full-page-title">
  Konto
</div>

<div class="cards">

  <div class="account-form-container">
    <div class="card white create-acc-card">
      <div class="title">
        <ng-container *ngIf="!signedUp else signed_up_title">
          Załóż konto
        </ng-container>
        <ng-template #signed_up_title>
          Dziękujemy za założenie konta!
        </ng-template>
      </div>

      <div *ngIf="!signedUp else signed_up_body" class="form-container">
        <app-sign-up-form [processing]="processing" (submitForm)="submit($event)" [(userAlreadyExists)]="userAlreadyExists"></app-sign-up-form>
      </div>

      <ng-template #signed_up_body>
        <div class="body">
          W celu dokończenia zakupów, dokończ rejestrację przez link aktywacyjny wysłany na e-mail.
        </div>
      </ng-template>
    </div>

<!--    <div class="login-box">-->
<!--      <div class="title">Mam konto</div>-->
<!--      <app-button size="small">-->
<!--        ZALOGUJ SIĘ ›-->
<!--      </app-button>-->
<!--    </div>-->
  </div>

</div>

<app-help-info></app-help-info>
