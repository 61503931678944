import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ConsentsInput } from '../../../shared/components/consents-input/consents-input.component';
import { corpEmail, email, nonEmptyStr, password, phoneNumber, required, validate, Validator } from '../../../shared/utils/validate';

export interface SignUpForm {
  readonly email: string;
  readonly password: string;
  readonly name: string;
  readonly surname: string;
  readonly phone: string;
  readonly marketingConsent: boolean;
  readonly orderConsent: boolean;
  // readonly consents: ConsentsInput;
}

@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss']
})
export class SignUpFormComponent implements OnInit {

  @Input() processing: boolean;
  @Input() corp = false;

  @Input()
  set userAlreadyExists(value) {
    this._userAlreadyExists = value;
    this.userAlreadyExistsChange.emit(value);
  }
  get userAlreadyExists() { return this._userAlreadyExists; }
  private _userAlreadyExists = false;

  @Output() userAlreadyExistsChange = new EventEmitter<boolean>();

  get name() { return this._name; }
  set name(value) {
    this.setAndValidateInput('name', value, validate(nonEmptyStr));
  }
  private _name = '';

  get surname() { return this._surname; }
  set surname(value) {
    this.setAndValidateInput('surname', value, validate(nonEmptyStr));
  }
  private _surname = '';

  get email() { return this._email; }
  set email(value) {
    this.setAndValidateInput('email', value, validate(nonEmptyStr, email, corpEmail(this.corp)));
    this.userAlreadyExists = false;
  }
  private _email = '';

  get phone() { return this._phone; }
  set phone(value) {
    this.setAndValidateInput('phone', value, validate(nonEmptyStr, phoneNumber));
  }
  private _phone = '';

  get password() { return this._password; }
  set password(value) {
    this.setAndValidateInput('password', value, validate(nonEmptyStr, password));
  }
  private _password = '';

  get allConsents() {
    return this.marketingConsent && this.orderConsent;
  }
  set allConsents(value) {
    this.marketingConsent = this.orderConsent = value;
  }

  get marketingConsent() { return this._marketingConsent; }
  set marketingConsent(value) {
    this._marketingConsent = value;
  }
  private _marketingConsent = false;

  get orderConsent() { return this._orderConsent; }
  set orderConsent(value) {
    this._orderConsent = value;
    this.consentError = !this._orderConsent;
  }
  private _orderConsent = false;

  nameError = false;
  surnameError = false;
  emailError = false;
  phoneError = false;
  passwordError = false;
  consentError = false;

  @Output() readonly submitForm = new EventEmitter<SignUpForm>();

  get enableSubmit() {
    return !(
      this.nameError
      || this.surnameError
      || this.emailError
      || this.phoneError
      || this.passwordError
    );
  }

  constructor() { }

  ngOnInit(): void {
  }

  getEmailError(mail: string): string {
    if (!mail) {
      return 'E-mail jest wymagany';
    }
    if (this.userAlreadyExists) {
      return 'E-mail jest już zarejestrowany';
    }
    if (!validate(email)(mail)) {
      return 'Niepoprawny e-mail';
    }
    if (!validate(corpEmail(this.corp))(mail)) {
      return 'Adres e-mail poza programem korporacyjnym';
    }
    return 'Niepoprawny e-mail';
  }

  submit() {
    if (!this.validateInputs()) {
      return;
    }
    if (!this.orderConsent) {
      this.consentError = true;
    }
    if (!this.enableSubmit) {
      return;
    }
    this.submitForm.emit({
      email: this.email,
      name: this.name,
      surname: this.surname,
      password: this.password,
      phone: this.phone,
      marketingConsent: this.marketingConsent,
      orderConsent: this.orderConsent
    });
  }

  private validateInputs() {
    const inputValidations: [string, Validator][] = [
      ['name', validate(nonEmptyStr)],
      ['surname', validate(nonEmptyStr)],
      ['email', validate(nonEmptyStr, email, corpEmail(this.corp))],
      ['phone', validate(nonEmptyStr, phoneNumber)],
      ['password', validate(nonEmptyStr, password)]
    ];

    let valid = true;

    for (const [name, validator] of inputValidations) {
      const validInput = this.validateInput(name, this[name], validator);
      valid = valid && validInput;
    }

    if (!this.orderConsent) {
      valid = false;
      this.consentError = true;
    }

    return valid;

    // return inputValidations
    //   .reduce((valid, [name, validator]) => valid && this.validateInput(name, this[name], validator), true);
  }

  private setAndValidateInput(name: string, value: any, validator: Validator): boolean {
    this['_' + name] = value;
    return this.validateInput(name, value, validator);
  }

  private validateInput(name: string, value: any, validator: Validator): boolean {
    const valid = validator(value);
    this[name + 'Error'] = !valid;
    return valid;
  }
}
