<div class="footer-item">
  <div class="title">
    FIBARO
  </div>
  <app-footer-fibaro-item></app-footer-fibaro-item>
</div>
<div class="footer-item">
  <div class="title">
    Wsparcie
  </div>
  <app-footer-support-item></app-footer-support-item>
</div>
<div class="footer-item">
  <div class="title">
    Zamówienia
  </div>
  <app-footer-order-item></app-footer-order-item>
</div>
<div class="footer-item">
  <div class="title">
    Kontakt
  </div>
  <app-footer-contact-item></app-footer-contact-item>
</div>
