<form (ngSubmit)="submit()">

  <div class="form-title">Adres dostawy</div>

  <div class="form-input">
    <label for="name">Imię*</label>
    <input [(ngModel)]="name" name="name" id="name">
    <div class="form-input-error" [style.opacity]="nameError ? 1 : 0">Imię jest wymagane</div>
  </div>

  <div class="form-input">
    <label for="surname">Nazwisko*</label>
    <input [(ngModel)]="surname" name="surname" id="surname">
    <div class="form-input-error" [style.opacity]="surnameError ? 1 : 0">Nazwisko jest wymagane</div>
  </div>

  <div class="form-input">
    <label for="street">Ulica*</label>
    <input [(ngModel)]="street" name="street" id="street">
    <div class="form-input-error" [style.opacity]="streetError ? 1 : 0">Ulica jest wymagana</div>
  </div>

  <div class="form-input">
    <label for="houseNo">Numer domu*</label>
    <input [(ngModel)]="houseNumber" name="houseNumber" id="houseNumber">
    <div class="form-input-error" [style.opacity]="houseNumberError ? 1 : 0">Numer domu jest wymagany</div>
  </div>

  <div class="form-input">
    <label for="flatNo">Numer mieszkania</label>
    <input [(ngModel)]="flatNumber" name="flatNumber" id="flatNumber">
  </div>

  <div class="form-input">
    <label for="postalCode">Kod pocztowy*</label>
    <input [(ngModel)]="postalCode" name="postalCode" id="postalCode">
    <div class="form-input-error" [style.opacity]="postalCodeError ? 1 : 0">Kod pocztowy jest wymagany</div>
  </div>

  <div class="form-input">
    <label for="city">Miasto*</label>
    <input [(ngModel)]="city" name="city" id="city">
    <div class="form-input-error" [style.opacity]="cityError ? 1 : 0">Miasto jest wymagane</div>
  </div>

  <div class="form-title">Dane kontaktowe</div>

  <div class="form-input">
    <label for="email">E-mail*</label>
    <input [(ngModel)]="email" name="email" id="email">
    <div class="form-input-error" [style.opacity]="emailError ? 1 : 0">
      <ng-container *ngIf="!email else invalid_email">
        E-mail jest wymagany
      </ng-container>
      <ng-template #invalid_email>
        Niepoprawny e-mail
      </ng-template>
    </div>
  </div>

  <div class="form-input">
    <label for="phone">Numer telefonu*</label>
    <input [(ngModel)]="phone" name="phone" id="phone">
    <div class="form-input-error" [style.opacity]="phoneError ? 1 : 0">
      <ng-container *ngIf="!phone else invalid_phone">
        Numer telefonu jest wymagany
      </ng-container>
      <ng-template #invalid_phone>
        Niepoprawny numer telefonu
      </ng-template>
    </div>
  </div>

  <div class="form-title">Komentarz do zamówienia</div>

  <div class="form-input">
    <label for="comment">Twoje uwagi</label>
    <input [(ngModel)]="comment" name="comment" id="comment" maxlength="1000">
  </div>

<!--  <div class="form-title">Zgody</div>-->

<!--  <div class="consents">-->
<!--    <mat-checkbox [(ngModel)]="allConsents" class="all-consents-checkbox" name="allConsents" [disableRipple]="true">-->
<!--      Zaakceptuj wszystkie zgody-->
<!--    </mat-checkbox>-->
<!--    <mat-checkbox [(ngModel)]="marketingConsent" name="offerConsent" [disableRipple]="true">-->
<!--      Wyrażam zgodę na otrzymywanie informacji handlowych Fibar Group S.A.-->
<!--      <div class="smaller-text">-->
<!--        Wyrażam zgodę na przetwarzanie przez Fibar Group S.A. moich danych osobowych (imię, nazwisko, e-mail)-->
<!--        w celu otrzymywania informacji handlowych drogą elektroniczną.-->
<!--      </div>-->
<!--    </mat-checkbox>-->
<!--    <mat-checkbox [(ngModel)]="orderConsent" name="secretConsent" [disableRipple]="true">-->
<!--      Akceptuję regulamin sklepu, regulamin usług oraz politykę prywatności*-->
<!--    </mat-checkbox>-->
<!--    <div class="consents-help">* zgody obowiązkowe</div>-->
<!--    <div class="error" [style.opacity]="consentError && !allConsents ? 1 : 0">-->
<!--      <div class="message">Zaznacz wymagane zgody</div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <app-button type="submit" [disabled]="!enableSubmit || processing" [processing]="processing" size="small">-->
<!--    DALEJ ›-->
<!--  </app-button>-->
</form>
