import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../shared/auth/services/auth.service';
import { finalize, map, switchMap, takeUntil } from 'rxjs/operators';
import { ChangePasswordForm } from '../../components/change-password-form/change-password-form.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  processing = false;

  email: string;
  resetCode: string;

  private destroy$ = new Subject<void>();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.email = params.get('email');
      this.resetCode = params.get('resetCode');

      console.log('log', { code: this.resetCode });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  submit(form: ChangePasswordForm) {
    this.processing = true;
    this.authService.changePassword(form.newPassword, this.email, this.resetCode).pipe(finalize(() => this.processing = false)).subscribe(() => {
      this.router.navigate(['/konto-korporacyjne/logowanie']);
    });
  }
}
