<form (ngSubmit)="submit()">
  <div class="form-input">
    <mat-checkbox name="sameAsDelivery" [(ngModel)]="sameAsDelivery">Ten sam co adres dostawy</mat-checkbox>
  </div>

  <div class="form-input">
    <label>Ulica*</label>
    <input [(ngModel)]="street" name="street" id="street">
    <div class="form-input-error" [style.opacity]="streetError ? 1 : 0">Ulica jest wymagana</div>
  </div>

  <div class="form-input">
    <label>Numer domu*</label>
    <input [(ngModel)]="houseNumber" name="houseNumber" id="houseNumber">
    <div class="form-input-error" [style.opacity]="houseNumberError ? 1 : 0">Numer domu jest wymagany</div>
  </div>

  <div class="form-input">
    <label>Numer mieszkania</label>
    <input [(ngModel)]="flatNumber" name="flatNumber" id="flatNumber">
  </div>

  <div class="form-input">
    <label>Kod pocztowy*</label>
    <input [(ngModel)]="postalCode" name="postalCode" id="postalCode">
    <div class="form-input-error" [style.opacity]="postalCodeError ? 1 : 0">Kod pocztowy jest wymagany</div>
  </div>

  <div class="form-input">
    <label>Miasto*</label>
    <input [(ngModel)]="city" name="city" id="city">
    <div class="form-input-error" [style.opacity]="cityError ? 1 : 0">Miasto jest wymagane</div>
  </div>

  <div class="form-input">
    <label>Twoje uwagi</label>
    <input [(ngModel)]="comment" name="comment" id="comment" maxlength="1000">
  </div>

<!--  <app-button type="submit" [disabled]="!enableSubmit || processing" [processing]="processing" size="small">-->
<!--    DALEJ ›-->
<!--  </app-button>-->
</form>
