import { Sellable } from '../models/product';
import { Cart, CartItem } from '../services/cart.service';
import { range } from './range';
import { User } from '../models/user';

// products discount can be 2k pln at max due to taxing reasons
const CART_PRICE_LIMIT = 3000;

export interface LimitedQuantityWithOptions {
  quantity: number;
  options: number[];
}

export function calculateLimitedQuantityWithOptions(cart: Cart,
                                                    user: User,
                                                    sellable: Sellable,
                                                    cartItem: CartItem,
                                                    variantDiscountedPrice?: number): LimitedQuantityWithOptions {
  if (sellable === 'required') {
    return {
      quantity: 1,
      options: range(1, 1)
    };
  }

  const cartItemQuantity = cartItem ? cartItem.quantity : 0;
  const maxAllowedQuantity = calculateMaxAllowedQuantity(cart, user, cartItem, variantDiscountedPrice);
  const maxQuantity = Math.max(cartItemQuantity, maxAllowedQuantity);

  return maxQuantity === 0
    ? {
      quantity: 0,
      options: [0]
    }
    : {
      quantity: cartItemQuantity || 1,
      options: range(1, maxQuantity)
    };
}

function calculateMaxAllowedQuantity(cart: Cart, user: User, cartItem: CartItem, variantDiscountedPrice: number): number {
  if (user.role === 'bank_employee') {
    return 100;
  }

  const cartPriceWithoutProduct = cartItem
    ? cart.totalDiscountedPrice - cartItem.quantity * cartItem.variant.discountedPrice
    : cart.totalDiscountedPrice;

  const price = cartItem ? cartItem.variant.discountedPrice : variantDiscountedPrice;

  let quantity = 0;
  let cartDiscountedPrice = 0;

  do {
    quantity++;
    cartDiscountedPrice = cartPriceWithoutProduct + price * quantity;
  } while (cartDiscountedPrice <= CART_PRICE_LIMIT);

  return quantity - 1;
}
