import { OrderItem } from './order-item';

export interface Order {
  readonly id: number;
  readonly createdAt: string;
  readonly status: string;
  readonly productsPrice: number;
  readonly productsDiscountedPrice: number;
  readonly totalPrice: number; // productsPrice + installation.price
  readonly totalDiscountedPrice: number; // productsDiscountedPrice + installation.price
  readonly clientRole: string;
  readonly orderNo: number;
  readonly orderNumber: string;
  readonly installation: Installation;
  readonly payment: Payment;
  readonly items: OrderItem[];
}

export interface Payment {
  readonly token: string;
  readonly merchantId: number;
}

export interface Installation {
  readonly type: string;
  readonly status: string;
  readonly price: number;
  readonly date: string;
  readonly notes: string;
}

export enum OrderStatus {
  waiting_for_payment = 'waiting_for_payment',
  paid = 'paid',
  payment_cancelled = 'payment_cancelled',
  in_progress = 'in_progress',
  delivery_shipped = 'delivery_shipped',
  delivery_completed = 'delivery_completed',
  stale = 'stale',
  completed = 'completed',
}