<!--<div *ngIf="cart && cart.model.delivery" class="items additional" style="border-top: none;">-->
<!--  <div class="card white">-->
<!--    <div class="title">-->
<!--      Dane odbiorcy-->
<!--    </div>-->
<!--    <div class="body">-->
<!--      {{ cart.model.delivery.name }} {{ cart.model.delivery.surname }}, {{ cart.model.delivery.city }}-->
<!--      <div style="margin-top: 10px">-->
<!--        <a [routerLink]="'/dostawa'">Zmień</a>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<div *ngIf="cart" class="items required">
  <app-cart-item *ngFor="let vm of cart.required" [vm]="vm"></app-cart-item>
</div>

<div *ngIf="cart && cart.additional.length > 0" class="items additional additional-cart-items">
  <app-cart-item *ngFor="let vm of cart.additional" [vm]="vm"></app-cart-item>
</div>

<div *ngIf="cart && cart.required.length === 0 && cart.additional.length === 0" class="empty-cart">
  <div style="font-size: 20px; margin: 40px 0;">Twój koszyk jest pusty</div>
  <div style="font-size: 24px; margin-bottom: 24px;">Przejdź do zakupów</div>
  <a class="btn" style="margin-bottom: 64px;" [routerLink]="'/'">DALEJ ›</a>
</div>

<div *ngIf="cart && cart.additional.length === 0" class="card white prompt-card">
  <div class="title">
    Dokup urządzenia
  </div>
  <div class="body">
    <app-button (click)="goToProductList()">Dalej ›</app-button>
  </div>
</div>

<div *ngIf="cart && cart.additional.length > 0 && !cart.model.installationMethod" class="card white prompt-card">
  <div class="title">
    Wybierz sposób instalacji
  </div>
  <div class="body">
    <app-button>Dalej ›</app-button>
  </div>
</div>

<div *ngIf="cart && cart.model.installationMethod" class="items additional">
  <div class="card white installation-card">
    <div class="title">
      {{ (cart.model.installationMethod.type | installation_type).description }}
    </div>
    <div class="body">
      <div class="actions">
        <a [routerLink]="'/sposob-instalacji'" alt="Zmień sposób instalacji">Zmień</a>
        <img (click)="clearInstallationMethod()" src="/assets/icons/thrash.png" alt="Usuń sposób instalacji" height="30px"/>
      </div>
      <div class="price-container">
        <div class="price-label">Cena:</div>
        <div class="price original">{{ cart.model.installationPrice | pln }}</div>
      </div>
    </div>
  </div>
</div>

<div class="items additional">
  <div class="card white secondary-card">
    <div class="title">
      Akceptujemy poniższe formy płatności
    </div>
    <div class="body">
      <!-- TODO: rebuild payment container with proper images -->
      <img class="payment" src="/assets/payment-placeholder.jpg" alt="payment methods">
    </div>
  </div>

  <div class="card white secondary-card">
    <div class="title">
      Bezpłatna dostawa
    </div>
    <div class="body">
      <div class="shipping">
        Kurier UPS
        <img src="/assets/UPS_logo.svg" alt="UPS">
      </div>
      Przewidywany czas wysyłki: 3 dni robocze
    </div>
  </div>

  <div class="card white secondary-card">
    <div class="title">
      Możliwość zwrotu do 30 dni
    </div>
    <div class="body">
      <a href="#">Warunki zwrotu</a>
    </div>
  </div>
</div>

<!--<app-help-info></app-help-info>-->

<app-cart-footer-summary (nextClick)="goNext()" [previousUrl]="'/'"></app-cart-footer-summary>

<div *ngIf="cart && (cart.required.length !== 0 || cart.additional.length !== 0)" class="cart-desktop-summary">
  <div class="title">
    <div class="label">Razem:</div>
    <div class="original-price">{{ cart.model.totalPrice | pln }}</div>
    <div class="discounted-price">{{ cart.model.totalDiscountedPrice | pln }}</div>
  </div>
  <div class="body" [class.blue]="!!cart.model.installationMethod">
    <div *ngIf="!cart.model.installationMethod" class="body-title">
      <ng-container *ngIf="cart.additional.length === 0">
        Dobierz urządzenia
      </ng-container>
      <ng-container *ngIf="cart.additional.length > 0">
        Wybierz sposób instalacji
      </ng-container>
    </div>
    <app-button (click)="goNext()">Dalej ›</app-button>
    <a class="nav-back-btn" [routerLink]="'/'">Wstecz</a>
  </div>
</div>

