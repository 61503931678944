import { Component, OnInit, ViewChild } from '@angular/core';
import { Cart, CartService } from '../../../shared/services/cart.service';
import { DeliveryForm, DeliveryFormComponent } from '../../components/delivery-form/delivery-form.component';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WorkflowService } from '../../../shared/services/workflow.service';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit {

  @ViewChild(DeliveryFormComponent) deliveryForm: DeliveryFormComponent;

  processing = false;

  readonly cart$: Observable<Cart>;

  constructor(
    readonly workflowService: WorkflowService,
    private readonly cartService: CartService,
    private readonly router: Router
  ) {
    this.cart$ = this.cartService.cart$;
  }

  ngOnInit(): void {
    // this.cartService.loadCart().subscribe();
  }

  submit(form: DeliveryForm) {
    this.processing = true;
    this.cartService.updateDelivery(form).pipe(finalize(() => this.processing = false)).subscribe(() => {
      this.router.navigate(['/podsumowanie']);
    });
  }
}
