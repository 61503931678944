import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private readonly authService: AuthService,
              private readonly router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.autoSignIn().pipe(map(user => {
      if (user) {
        if (user.role === 'new_policy' && !user.isNewInsurance) {
          return this.router.parseUrl('/konto-klienta/logowanie');
        }
        return true;
      } else {
        return this.router.parseUrl('/weryfikacja');
      }
    }));
  }
}
