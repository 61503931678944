import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { range } from '../../../../shared/utils/range';
import { CartService } from '../../../../shared/services/cart.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { CartItemViewModel } from '../../../../shared/services/cart-view-model.store';
import { img } from '../../../../shared/utils/img';
import { Subject } from 'rxjs';
import { calculateLimitedQuantityWithOptions } from '../../../../shared/utils/limited-quantity';
import { AuthService } from '../../../../shared/auth/services/auth.service';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnInit, OnDestroy {

  readonly image = img;

  @Input() vm: CartItemViewModel;

  processing = false;

  quantityOptions = range(1, 10);

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly authService: AuthService,
    private readonly cartService: CartService,
  ) {}

  ngOnInit(): void {
    this.cartService.cart$.pipe(takeUntil(this.destroy$)).subscribe(cart => {
      const { options } = calculateLimitedQuantityWithOptions(cart, this.authService.user, this.vm.product.sellable, this.vm.item);
      this.quantityOptions = options;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateCartItem(productVariantId: number, quantity: number): void {
    this.processing = true;
    this.cartService.updateItem(productVariantId, quantity).pipe(finalize(() => this.processing = false)).subscribe();
  }

  removeCartItem(productVariantId: number): void {
    this.processing = true;
    this.cartService.removeItem(productVariantId).pipe(finalize(() => this.processing = false)).subscribe();
  }
}
