<div class="card white reset-password">
  <div class="title">
    Resetuj hasło
  </div>

  <ng-container *ngIf="!mailSent else mail_sent">
    <div class="subtitle long">
      link do zmiany hasła wyślemy na podany adres&nbsp;e-mail
    </div>

    <app-reset-password-form [processing]="processing" (submitForm)="submit($event)"></app-reset-password-form>

    <a (click)="goBack()">Wróć ›</a>
  </ng-container>

  <ng-template #mail_sent>
    <div class="body">
      Dziękujemy.<br>
      Na skrzynkę mailową zostały wysłane dalsze instrukcje dotyczące zmiany hasła.
    </div>
  </ng-template>
</div>

<app-help-info></app-help-info>
