import { Observable } from 'rxjs';
import { endpoint } from '../utils/endpoint';
import { HttpClient } from '@angular/common/http';
import { Order } from '../models/order';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(private http: HttpClient) {}

  create(): Observable<Order> {
    const url = endpoint('/orders');
    return this.http.post<Order>(url, null, { withCredentials: true })
      .pipe(
        tap(order => {
          const { token, merchantId } = order.payment;
          const redirectTo = `https://cashierui-apiuat.test.secure.eservice.com.pl?token=${token}&merchantId=${merchantId}&integrationMode=HostedPayPage`;
          window.location.href = redirectTo;
        })
      );
  }
}
