<div class="card white">
  <div class="title">
    {{ vm.product.category.name }}
  </div>
  <div class="body">
    <div class="product">
      <img class="product-img" [src]="image(vm.item.variant.imageUris[0])" [alt]="vm.product.title" />
      <div class="summary">
        <div class="name">
          {{ vm.product.title }}
          <div *ngIf="vm.item.required" style="align-self: flex-start">
            <i><small>produkt wymagany</small></i>
          </div>
        </div>
        <div *ngIf="!vm.item.required" class="actions">
          <ng-container *ngIf="!processing else in_progress">
            <mat-select [value]="vm.item.quantity" (valueChange)="updateCartItem(vm.item.variant.id, $event)">
              <mat-option *ngFor="let option of quantityOptions" [value]="option">{{ option }}</mat-option>
            </mat-select>
            <img (click)="removeCartItem(vm.item.variant.id)" class="remove-item" src="/assets/icons/thrash.png" alt="Usuń">
          </ng-container>
          <ng-template #in_progress>
            <mat-spinner diameter="25"></mat-spinner>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <app-discounted-price
    [originalPrice]="vm.totalPrice"
    [discountedPrice]="vm.totalDiscountedPrice">
  </app-discounted-price>
</div>
