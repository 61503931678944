import { Component, OnInit, ViewChild } from '@angular/core';
import { DeliveryForm } from '../../components/delivery-form/delivery-form.component';
import {
  InstallationAddressForm,
  InstallationAddressFormComponent
} from '../../components/installation-address-form/installation-address-form.component';
import { Cart, CartService } from '../../../shared/services/cart.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { OrderService } from '../../../shared/services/order.service';
import { finalize, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-installation-address',
  templateUrl: './installation-address.component.html',
  styleUrls: ['./installation-address.component.scss']
})
export class InstallationAddressComponent implements OnInit {

  @ViewChild(InstallationAddressFormComponent) installationAddressForm: InstallationAddressFormComponent;

  processing = false;

  readonly cart$: Observable<Cart>;

  constructor(
    private readonly cartService: CartService,
    private readonly orderService: OrderService,
    private readonly router: Router
  ) {
    this.cart$ = this.cartService.cart$;
  }

  ngOnInit(): void {
  }

  submit(form: InstallationAddressForm) {
    this.processing = true;
    this.cartService.updateInstallationAddress(form)
      .pipe(
        finalize(() => this.processing = false),
        // switchMap(() => this.orderService.create())
      )
      .subscribe(order => {
        this.router.navigate(['/platnosc']);
      });
  }
}
