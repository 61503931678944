import { Component, Input, OnInit } from '@angular/core';
import { ProductUseCase } from '../../../../shared/models/product-use-case.model';
import { ProductDetails } from '../../../../shared/models/product-details';
import { img } from '../../../../shared/utils/img';
import { ProductDetailsViewModel } from '../../../../shared/services/product-details-view-model.store';

@Component({
  selector: 'app-product-details-use-cases',
  templateUrl: './product-details-use-cases.component.html',
  styleUrls: ['./product-details-use-cases.component.scss']
})
export class ProductDetailsUseCasesComponent implements OnInit {

  @Input() productDetails: ProductDetailsViewModel;

  image = img;

  constructor() { }

  ngOnInit(): void {
  }

}
