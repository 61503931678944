<div class="card white user-agreements-card">

    <div class="title">
      Zmień zgody
    </div>
    <div class="form-container">
      <app-user-agreements-form [user]="user$ | async" [processing]="processing" (submitForm)="submit($event)"></app-user-agreements-form>
    </div>
    
</div>
    
<app-help-info></app-help-info>
