import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';

@Pipe({
  name: 'pln',
  pure: true
})
export class PlnPipe implements PipeTransform {
  constructor(private readonly currencyPipe: CurrencyPipe) {}

  transform(value: number, ...args: any[]): string {
    return this.currencyPipe.transform(value, 'zł', 'code');
  }
}
