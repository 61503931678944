<form (ngSubmit)="submit()">
  <div class="form-input">
    <label for="name">Imię</label>
    <input [(ngModel)]="name" name="name" id="name">
    <div class="form-input-error" [style.opacity]="nameError ? 1 : 0">Imię jest wymagane</div>
  </div>

  <div class="form-input">
    <label for="surname">Nazwisko</label>
    <input [(ngModel)]="surname" name="surname" id="surname">
    <div class="form-input-error" [style.opacity]="surnameError ? 1 : 0">Nazwisko jest wymagane</div>
  </div>

  <div class="form-input">
    <label for="email">E-mail</label>
    <input [(ngModel)]="email" name="email" id="email">
    <div class="form-input-error" [style.opacity]="emailError ? 1 : 0">
      <ng-container *ngIf="!email else invalid_email">
        E-mail jest wymagany
      </ng-container>
      <ng-template #invalid_email>
        Niepoprawny e-mail
      </ng-template>
    </div>
  </div>
  
  <div class="form-input">
    <label for="phone">Numer telefonu</label>
    <input [(ngModel)]="phone" name="phone" id="phone">
    <div class="form-input-error" [style.opacity]="phoneError ? 1 : 0">
      <ng-container *ngIf="!phone else invalid_phone">
        Numer telefonu jest wymagany
      </ng-container>
      <ng-template #invalid_phone>
        Niepoprawny numer telefonu
      </ng-template>
    </div>
  </div>

  <app-button type="submit" [disabled]="!enableSubmit || processing" [processing]="processing" size="small">
    ZMIEŃ
  </app-button>
  <a [routerLink]="'/twoje-konto'" [routerLinkActive]="'active'" class="back-link">Wróć ›</a>
</form>
