<form (ngSubmit)="submit()">
  <div class="form-input">
    <mat-checkbox name="fibaroMarketingConsent" [(ngModel)]="fibaroMarketingConsent">
      Akceptuję <a href="#">zgody marketingowe</a> FIBARO
    </mat-checkbox>
  </div>
    <app-button type="submit" [disabled]="processing" [processing]="processing" size="small">
      ZMIEŃ
    </app-button>
    <a [routerLink]="'/twoje-konto'" [routerLinkActive]="'active'" class="back-link">Wróć ›</a>
</form>
