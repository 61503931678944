<ng-container *ngIf="!cart else loaded">
  <mat-spinner diameter="40"></mat-spinner>
</ng-container>

<ng-template #loaded>

  <div *ngIf="type === 'long'" class="long-summary">
    <div class="title">
      Produkty
    </div>
    <div class="title">
      <div class="label">Cena:</div>
      <div class="original-price discounted">{{ cart.model.totalPrice - cart.model.installationPrice | pln }}</div>
      <div class="discounted-price">{{ cart.model.totalDiscountedPrice - cart.model.installationPrice | pln }}</div>
    </div>
    <div class="title">
      Instalacja
    </div>
    <div class="title">
      <div class="label">Cena:</div>
      <div class="original-price">{{ cart.model.installationPrice | pln }}</div>
    </div>
    <div class="title">
      Dostawa
    </div>
    <div class="title">
      <div class="label">Cena:</div>
      <div class="original-price">{{ 0 | pln }}</div>
    </div>
    <div class="separator"></div>
  </div>

  <div class="title">
    <div class="label">Razem:</div>
    <div class="original-price discounted">{{ cart.model.totalPrice | pln }}</div>
    <div class="discounted-price">{{ cart.model.totalDiscountedPrice | pln }}</div>
  </div>
  <div class="body" [class.blue]="!!cart.model.installationMethod">
    <div *ngIf="!cart.model.installationMethod" class="body-title">
      <ng-container *ngIf="cart.additional.length === 0">
        Dobierz urządzenia
      </ng-container>
      <ng-container *ngIf="cart.additional.length > 0">
        Wybierz sposób instalacji
      </ng-container>
    </div>
    <app-button (click)="goNext()">{{ label || 'Dalej' }} ›</app-button>
    <a *ngIf="previousUrl" class="nav-back-btn" [routerLink]="previousUrl">Wstecz</a>
  </div>
</ng-template>
