import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Cart, CartService } from '../../../shared/services/cart.service';
import { WorkflowService } from '../../../shared/services/workflow.service';
import { CartViewModel, CartViewModelStore } from '../../../shared/services/cart-view-model.store';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit {

  readonly cart$: Observable<Cart>;

  readonly vm$: Observable<CartViewModel>;

  constructor(
    readonly workflowService: WorkflowService,
    private cartService: CartService,
    private cartViewModelStore: CartViewModelStore
  ) {
    this.cart$ = this.cartService.cart$;
    this.vm$ = this.cartViewModelStore.vm$;
  }

  ngOnInit(): void {
  }

  clearInstallationMethod() {

  }
}
