<div class="phone">
  <img src="/assets/phone_small.svg" alt="Telefon" />
  <a href="tel:+48618801000">+48 61 880 1000</a>
</div>
<div class="opening-hours">
  <div class="entry">
    <div class="days">
      pn. - pt.
    </div>
    <div class="hours">
      10.00 - 18.00
    </div>
  </div>
</div>
<div class="email">
  <img src="/assets/mail_small.svg" alt="E-mail" />
  <a href="mailto:pko@fibaro.com">pko@fibaro.com</a>
</div>
