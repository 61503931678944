import { Component, EventEmitter, Input, Output } from '@angular/core';
import { nonEmptyStr, validate, Validator, email } from '../../../shared/utils/validate';

export interface ResetPasswordForm {
  email: string;
}

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent {
  @Input() processing: boolean;

  get email() { return this._email; }
  set email(value) {
    this.setAndValidateInput('email', value, validate(nonEmptyStr, email));
  }
  private _email = '';

  emailError = false;

  @Output() readonly submitForm = new EventEmitter<ResetPasswordForm>();

  get enableSubmit() {
    return !this.emailError;
  }

  submit() {
    if (!this.validateInputs()) {
      return;
    }
    if (!this.enableSubmit) {
      return;
    }
    this.submitForm.emit({
      email: this.email
    });
  }

  private validateInputs() {
    const inputValidations: [string, Validator][] = [
      ['email', validate(nonEmptyStr, email)],
    ];

    let valid = true;

    for (const [name, validator] of inputValidations) {
      const validInput = this.validateInput(name, this[name], validator);
      valid = valid && validInput;
    }

    return valid;
  }

  private setAndValidateInput(name: string, value: any, validator: Validator): boolean {
    this['_' + name] = value;
    return this.validateInput(name, value, validator);
  }

  private validateInput(name: string, value: any, validator: Validator): boolean {
    const valid = validator(value);
    this[name + 'Error'] = !valid;
    return valid;
  }
}
