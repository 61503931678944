import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../../../shared/services/order.service';
import { of } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CartService } from '../../../../shared/services/cart.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  paid = false;

  constructor(
    private readonly orderService: OrderService,
    private readonly router: Router,
    private readonly cartService: CartService,
  ) {}

  ngOnInit(): void {
    of(null)
      .pipe(
        delay(3000),
        switchMap(() => this.orderService.create()),
        switchMap(() => this.cartService.loadCart(true))
      )
      .subscribe(() => {
        this.router.navigate(['/']);
        // this.paid = true;
      });
  }

  private startOrder() {
    this.orderService.create().subscribe();
  }
}
