<div class="card white user-data-card">

    <div class="title">
      Zmień adres dostawy
    </div>
    <div class="form-container">
      <app-user-delivery-form [user]="user$ | async" [processing]="processing" (submitForm)="submit($event)"></app-user-delivery-form>
    </div>
    
</div>
    
<app-help-info></app-help-info>
