import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { Product } from '../models/product';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { slugFrom } from '../utils/slugFrom';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private readonly _products$ = new BehaviorSubject<Product[]>(undefined);
  readonly products$ = this._products$.asObservable();
  get products() { return this._products$.getValue(); }

  constructor(private readonly http: HttpClient) {}

  loadProducts(reload = true) {
    if (this.products && !reload) {
      return of(this.products);
    }
    const url = `${environment.apiRoot}/products`;
    this._products$.next(undefined);
    return this.http.get<Product[]>(url, { withCredentials: true })
      .pipe(
        map(products => products.map(product => ({
          ...product,
          category: {
            ...product.category,
            slug: slugFrom(product.category.name)
          }
        }))),
        tap(products => this._products$.next(products))
      );
  }
}
