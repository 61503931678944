<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>FIBARO</mat-panel-title>
    </mat-expansion-panel-header>
    <app-footer-fibaro-item></app-footer-fibaro-item>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Wsparcie</mat-panel-title>
    </mat-expansion-panel-header>
    <app-footer-support-item></app-footer-support-item>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Zamówienia</mat-panel-title>
    </mat-expansion-panel-header>
    <app-footer-order-item></app-footer-order-item>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Kontakt</mat-panel-title>
    </mat-expansion-panel-header>
    <app-footer-contact-item></app-footer-contact-item>
  </mat-expansion-panel>
</mat-accordion>
