import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { InstallationType } from '../models/installation-type';
import { DeliveryForm } from '../../shop/components/delivery-form/delivery-form.component';
import {
  InstallationAddressForm,
  InstallationAddressFormComponent
} from '../../shop/components/installation-address-form/installation-address-form.component';

export interface CartItem {
  productId: number;
  quantity: number;
  required: boolean;
  variant: {
    id: number;
    discountedPrice: number;
    pricePerUnit: number;
    color: string;
    imageUris: string[];
  };
}

export interface CartItemUpdate {
  readonly productVariantId: number;
  readonly quantity: number;
}

export interface Cart {
  readonly items: CartItem[];
  readonly installationPricing: {
    onsite: InstallationMethodPricing;
    remote: InstallationMethodPricing;
    self: InstallationMethodPricing;
  };
  readonly installationPrice: number;
  readonly installationMethod: {
    id: number;
    type: InstallationType;
  };
  readonly totalPrice: number;
  readonly totalDiscountedPrice;
  readonly delivery: Delivery;
  readonly installationAddress: InstallationAddress;
}

export interface InstallationMethodPricing {
  id: number;
  type: InstallationType;
  price: number;
}

export interface CartUpdate {
  readonly installationMethod?: { id: number };
  readonly items?: CartItemUpdate[];
  readonly delivery?: Delivery;
  readonly installationAddress?: InstallationAddress;
}

export interface Delivery {
  name: string;
  surname: string;
  street: string;
  houseNo: string;
  flatNo: string;
  postalCode: string;
  city: string;
  email: string;
  phone: string;
  comment: string;
  marketingConsent: boolean;
  orderConsent: boolean;
}

export interface InstallationAddress {
  street: string;
  houseNo: string;
  flatNo: string;
  postalCode: string;
  city: string;
  period: string;
  comment: string;
}

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private readonly _cart$ = new BehaviorSubject<Cart>(undefined);
  readonly cart$ = this._cart$.asObservable();
  get cart() { return this._cart$.getValue(); }

  // private pendingUpdates: CartItemUpdate[] = [];

  // private updating = false;

  private loading = false;

  constructor(private readonly http: HttpClient) {}

  updateItem(productVariantId: number, quantity: number): Observable<Cart> {
    return this.patchCart({
      items: [
        { productVariantId, quantity }
      ]
    });
  }

  removeItem(productVariantId: number): Observable<Cart> {
    return this.patchCart({
      items: [
        { productVariantId, quantity: -1_000 }
      ]
    });
  }

  selectInstallationMethod(installationMethodId: number): Observable<Cart> {
    return this.patchCart({
      installationMethod: {
        id: installationMethodId
      }
    });
  }

  clearInstallationMethod(): Observable<Cart> {
    return this.patchCart({
      installationMethod: {
        id: null
      }
    });
  }

  updateDelivery(delivery: DeliveryForm): Observable<Cart> {
    return this.patchCart({
      delivery
    });
  }

  updateInstallationAddress(address: InstallationAddressForm) {
    return this.patchCart({
      installationAddress: address
    });
  }

  loadCart(reload = true) {
    if (this.cart && !reload) {
      return of(this.cart);
    }
    const url = `${environment.apiRoot}/cart`;
    this._cart$.next(undefined);
    return this.http.get<Cart>(url, { withCredentials: true })
      .pipe(
        tap(cart => this.updateCart(cart))
      );
  }

  reset() {
    this._cart$.next(undefined);
  }

  private patchCart(update: CartUpdate): Observable<Cart> {
    const url = `${environment.apiRoot}/cart`;
    return this.http.patch<Cart>(url, update, { withCredentials: true })
      .pipe(
        tap(cart => this.updateCart(cart))
      );
  }

  private updateCart(cart: Cart): void {
    this._cart$.next(cart);
  }
}
