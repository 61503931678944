import { NgModule } from '@angular/core';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PkoFibaroBannerComponent } from './components/pko-fibaro-banner/pko-fibaro-banner.component';
import { HelpInfoComponent } from './components/help-info/help-info.component';
import { IconWithLinkComponent } from './components/icon-with-link/icon-with-link.component';
import { PolicyNoInputComponent } from './components/policy-no-input/policy-no-input.component';
import { FormsModule } from '@angular/forms';
import { MobileFooterComponent } from './components/footer/mobile-footer/mobile-footer.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { SocialMediaComponent } from './components/social-media/social-media.component';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { CopyrightsComponent } from './components/copyrights/copyrights.component';
import { FooterContactItemComponent } from './components/footer/items/footer-contact-item/footer-contact-item.component';
import { FooterFibaroItemComponent } from './components/footer/items/footer-fibaro-item/footer-fibaro-item.component';
import { FooterOrderItemComponent } from './components/footer/items/footer-order-item/footer-order-item.component';
import { FooterSupportItemComponent } from './components/footer/items/footer-support-item/footer-support-item.component';
import { DesktopFooterComponent } from './components/footer/desktop-footer/desktop-footer.component';
import { FooterComponent } from './components/footer/footer/footer.component';
import { ButtonModule } from './components/button/button.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { CategoryIconPipe } from './pipes/category-icon.pipe';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { ConsentsInputComponent } from './components/consents-input/consents-input.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CartSummaryComponent } from './components/cart-summary/cart-summary.component';
import { PlnPipe } from './pipes/pln.pipe';
import { CartItemComponent } from '../shop/pages/cart/cart-item/cart-item.component';
import { InstallationTypePipe } from './pipes/installation-type.pipe';
import { StepDisplayComponent } from './components/step-display/step-display.component';
import { CartSummaryBoxComponent } from './components/cart-summary-box/cart-summary-box.component';
import { OrderSummaryItemComponent } from './components/order-summary-item/order-summary-item.component';
import { OrderStatusPipe } from './pipes/order-status.pipe';
import { DiscountedPriceComponent } from './components/discounted-price/discounted-price.component';
import { ExpandableTextComponent } from './components/expandable-text/expandable-text.component';

const FOOTER = [
  DesktopFooterComponent,
  MobileFooterComponent,
  FooterComponent,
  FooterContactItemComponent,
  FooterFibaroItemComponent,
  FooterOrderItemComponent,
  FooterSupportItemComponent
];

const COMPONENTS = [
  ...FOOTER,
  CartItemComponent,
  CartSummaryComponent,
  CartSummaryBoxComponent,
  ConsentsInputComponent,
  CopyrightsComponent,
  ExpandableTextComponent,
  HelpInfoComponent,
  IconWithLinkComponent,
  NavbarComponent,
  OrderSummaryItemComponent,
  PkoFibaroBannerComponent,
  PolicyNoInputComponent,
  ProductCardComponent,
  SocialMediaComponent,
  StepDisplayComponent,
  DiscountedPriceComponent
];

const MODULES = [
  ButtonModule,
  CommonModule,
  FormsModule,
  MatCheckboxModule,
  MatDialogModule,
  MatExpansionModule,
  MatSelectModule,
  MatProgressSpinnerModule,
  MatTooltipModule
];

const CUSTOM_PIPES = [
  CategoryIconPipe,
  InstallationTypePipe,
  PlnPipe,
  OrderStatusPipe
];

@NgModule({
  imports: [
    ...MODULES,
    RouterModule,
  ],
  declarations: [
    ...COMPONENTS,
    ...CUSTOM_PIPES,
  ],
  providers: [
    ...CUSTOM_PIPES,
    CurrencyPipe
  ],
  exports: [
    ...COMPONENTS,
    ...CUSTOM_PIPES,
    ...MODULES
  ]
})
export class SharedModule {}
