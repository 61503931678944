import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../shared/auth/services/auth.service';

export interface SignInForm {
  readonly email: string;
  readonly password: string;
}

@Component({
  selector: 'app-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['./sign-in-form.component.scss']
})
export class SignInFormComponent implements OnInit {

  @Input() processing = false;
  @Input() unauthorizedError = false;

  @Output() readonly submitForm = new EventEmitter<SignInForm>();

  email: string;
  password: string;

  get enableSubmit() {
    return this.email && this.password;
  }

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
  }

  submit() {
    if (!this.enableSubmit) {
      return;
    }
    this.submitForm.emit({
      email: this.email,
      password: this.password
    });
  }
}
