<div class="full-page-title">
  Wybierz sposób instalacji
</div>

<div class="installation-types">
  <div class="card white installation-type">
    <div class="premium-badge">
      POLECAMY
    </div>
    <div class="title">
      <img [src]="('onsite' | installation_type).iconUrl" [alt]="('onsite' | installation_type).title" />
      {{ ('onsite' | installation_type).title }}
    </div>
    <div class="body">
      <div class="body-title">
        {{ ('onsite' | installation_type).description }}
      </div>
      <div class="list">
        <div class="list-title">Zakres usługi:</div>
        <ul>
          <li>montaż i konfiguracja urządzeń w Twoim domu,</li>
          <li>instalacja aplikacji w telefonie komórkowym,</li>
          <li>aktywacja połączenia urządzeń z usługą Assistance dla domu 24/7 PKO Ubezpieczenia,</li>
          <li>prezentacja funkcjonalności FIBARO.</li>
        </ul>
      </div>
      <div class="price-container">
        <div class="price-label">Cena:</div>
        <div *ngIf="cart$ | async; let cart" class="price original">{{ cart.installationPricing.onsite.price | pln }}</div>
      </div>
    </div>
    <app-button *ngIf="cart$ | async; let cart" [secondary]="isSelected(cart.installationPricing.onsite)"
                [processing]="processing" [disabled]="processing" (click)="selectedInstallationTypeId = cart.installationPricing.onsite.id">
      {{ isSelected(cart.installationPricing.onsite) ? 'WYBRANO' : 'WYBIERZ' }}
    </app-button>
  </div>

  <div class="card white installation-type">
    <div class="title">
      <img [src]="('remote' | installation_type).iconUrl" [alt]="('remote' | installation_type).title" />
      {{ ('remote' | installation_type).title }}
    </div>
    <div class="body">
      <div class="body-title">
        {{ ('remote' | installation_type).description }}
      </div>
      <div class="list">
        <div class="list-title">Zakres usługi:</div>
        <ul>
          <li>godzinna rozmowa telefoniczna z kursem wprowadzającym do korzystania z FIBARO,</li>
          <li>zdalna konfiguracja urządzeń instalacja aplikacji na jednym urządzeniu,</li>
          <li>aktywacja połączenia urządzeń z usługą Assistance dla domu 24/7 PKO Ubezpieczenia.</li>
        </ul>
      </div>
      <div class="price-container">
        <div class="price-label">Cena:</div>
        <div *ngIf="cart$ | async; let cart" class="price original">{{ cart.installationPricing.remote.price | pln }}</div>
      </div>
    </div>
    <app-button *ngIf="cart$ | async; let cart" [secondary]="isSelected(cart.installationPricing.remote)"
                [processing]="processing" [disabled]="processing" (click)="selectedInstallationTypeId = cart.installationPricing.remote.id">
      {{ isSelected(cart.installationPricing.remote) ? 'WYBRANO' : 'WYBIERZ' }}
    </app-button>
  </div>

  <div class="card white installation-type">
    <div class="title">
      <img [src]="('self' | installation_type).iconUrl" [alt]="('self' | installation_type).title" />
      {{ ('self' | installation_type).title }}
    </div>
    <div class="body">
      <div class="body-title">
        {{ ('self' | installation_type).description }}
      </div>
      <div class="list">
        <p>
          System smart home FIBARO możesz zainstalować i skonfigurować samodzielnie.
        </p>
        <p>
          Przygotowaliśmy przejrzystą instrukcję, z którą krok po kroku rozpoczniesz przygodę z
          produktami FIBARO i programem PKO Inteligentny dom.
        </p>
        <p>
          Do instalacji i konfiguracji potrzebujesz komputera z dostępem do Internetu oraz pewnej
          biegłości technicznej. Jeśli napotkasz trudności Support FIBARO jest do
          Twojej dyspozycji +48 618 80 10 00.
        </p>
<!--        <p>-->
<!--          Aby pobrać instrukcję odwiedź stronę: <a href="https://bit.ly/pko-fibaro">bit.ly/pko-fibaro</a>-->
<!--          <br>-->
<!--          Pamiętaj, możesz także później dokupić usługę Instalacji przy pomocy-->
<!--          Instalatora FIBARO lub usługę Zdalnej konfiguracji.-->
<!--        </p>-->
<!--        <ul>-->
<!--          <li>montaż i konfiguracja urządzeń w Twoim domu,</li>-->
<!--          <li>instalacja aplikacji w telefonie komórkowym,</li>-->
<!--          <li>aktywacja połączenia urządzeń z usługą Assistance dla domu 24/7 PKO Ubezpieczenia,</li>-->
<!--          <li>prezentacja funkcjonalności FIBARO.</li>-->
<!--        </ul>-->
      </div>
      <div class="price-container">
        <div class="price-label">Cena:</div>
        <div *ngIf="cart$ | async; let cart" class="price original">{{ cart.installationPricing.self.price | pln }}</div>
      </div>
    </div>
    <app-button *ngIf="cart$ | async; let cart" [secondary]="isSelected(cart.installationPricing.self)"
                [processing]="processing" [disabled]="processing" (click)="selectedInstallationTypeId = cart.installationPricing.self.id">
      {{ isSelected(cart.installationPricing.self) ? 'WYBRANO' : 'WYBIERZ' }}
    </app-button>
  </div>
</div>

<div class="remarks">
  *Cena instalacji zależy od rodzaju i ilości wybranych urządzeń
</div>

<app-cart-footer-summary (nextClick)="saveAndContinue()"></app-cart-footer-summary>
