import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-support-item',
  templateUrl: './footer-support-item.component.html',
  styleUrls: ['./footer-support-item.component.scss']
})
export class FooterSupportItemComponent {
  @HostBinding('class.footer-links') footerLinks = true;
}
