import { Component, Output, EventEmitter, Input } from '@angular/core';

export interface PolicyNoForm {
  policyNo: string;
  secretConsent: boolean;
  smartHomeConsent: boolean;
}

@Component({
  selector: 'app-policy-no-form',
  templateUrl: './policy-no-form.component.html',
  styleUrls: ['./policy-no-form.component.scss']
})
export class PolicyNoFormComponent {
  @Input()
  set invalidPolicyNoError(value) {
    if (this._invalidPolicyNoError === value) {
      return;
    }
    this._invalidPolicyNoError = value;
    this.invalidPolicyNoErrorChange.emit(value);
  }
  get invalidPolicyNoError() { return this._invalidPolicyNoError; }
  private _invalidPolicyNoError = false;

  @Input() processing = false;

  @Output() readonly invalidPolicyNoErrorChange = new EventEmitter<boolean>();

  @Output() readonly submitForm = new EventEmitter<PolicyNoForm>();

  get allConsents() {
    return this.secretConsent && this.smartHomeConsent;
  }
  set allConsents(value) {
    this.secretConsent = this.smartHomeConsent = value;
  }

  get secretConsent() { return this._secretConsent; }
  set secretConsent(value) {
    this._secretConsent = value;
  }
  private _secretConsent = false;

  get smartHomeConsent() { return this._smartHomeConsent; }
  set smartHomeConsent(value) {
    this._smartHomeConsent = value;
  }
  private _smartHomeConsent = false;

  get policyNo() { return this._policyNo; }
  set policyNo(value) {
    this._policyNo = value;
  }
  private _policyNo = '';

  get enableSubmit() {
    // return this.policyNo.length === 12 && this.allConsents;
    return this.policyNo && this.allConsents;
  }

  consentError = false;

  submit() {
    if (!this.allConsents) {
      this.consentError = true;
      return;
    }
    if (!this.enableSubmit) {
      return;
    }
    this.submitForm.emit({
      policyNo: this.policyNo,
      secretConsent: this.secretConsent,
      smartHomeConsent: this.smartHomeConsent
    });
  }
}
