<app-pko-fibaro-banner type="small" title="Konto korporacyjne"></app-pko-fibaro-banner>

<div class="card white">
  <div class="title">Aktywacja konta użytkownika</div>
  <div class="body">
    Proszę czekać, trwa aktywacja konta użytkownika...

    <mat-spinner diameter="25"></mat-spinner>
  </div>
</div>
