<ng-container *ngIf="productDetails.model.useCases else no_use_cases">
  <div *ngFor="let useCase of productDetails.model.useCases" class="use-case">
    <img [src]="image('use_cases/' + useCase.imageUri)" [alt]="useCase.title" />
    <div class="content">
      <p>
        <strong>
          {{ useCase.title }}
        </strong>
      </p>
      <p>
        {{ useCase.description }}
      </p>
    </div>
  </div>

</ng-container>

<ng-template #no_use_cases>
  <p>Nie dodano przypadków użycia dla danego produktu.</p>
</ng-template>
