import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/shared/models/user';

export interface UserAgreementsForm {
  fibaroMarketingConsent: boolean;
}

@Component({
  selector: 'app-user-agreements-form',
  templateUrl: './user-agreements-form.component.html',
  styleUrls: ['./user-agreements-form.component.scss']
})
export class UserAgreementsFormComponent implements OnInit {
  @Input() user: User;
  @Input() processing: boolean;

  get fibaroMarketingConsent() { return this._fibaroMarketingConsent; }
  set fibaroMarketingConsent(value) { this._fibaroMarketingConsent = value; }
  private _fibaroMarketingConsent = false;

  @Output() readonly submitForm = new EventEmitter<UserAgreementsForm>();

  ngOnInit(): void {
    this.fibaroMarketingConsent = this.user.fibaroMarketingConsent;
  }

  submit() {
    this.submitForm.emit({
      fibaroMarketingConsent: this.fibaroMarketingConsent
    });
  }
}
