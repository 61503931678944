<div class="category">
  {{ vm.product.category.name }}
</div>
<div class="thumbnail">
  <img [src]="image(vm.item.variant.imageUris[0])" />
</div>
<div class="name">
  {{ vm.product.title }} ({{ vm.item.quantity }} szt.)
</div>
<app-discounted-price
  [originalPrice]="vm.totalPrice"
  [discountedPrice]=" vm.totalDiscountedPrice">
</app-discounted-price>