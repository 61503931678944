import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-discounted-price',
  templateUrl: './discounted-price.component.html',
  styleUrls: ['./discounted-price.component.scss']
})
export class DiscountedPriceComponent {

  @Input() label: string = 'Cena:';
  @Input() originalPrice: number;
  @Input() discountedPrice: number;

  get discountPercentage(): number {
    return Math.round((1 - this.discountedPrice / this.originalPrice) * 100);
  }

}
