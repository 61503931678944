import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-order-item',
  templateUrl: './footer-order-item.component.html',
  styleUrls: ['./footer-order-item.component.scss']
})
export class FooterOrderItemComponent {
  @HostBinding('class.footer-links') footerLinks = true;
}
