<app-pko-fibaro-banner type="small" title="Konto korporacyjne"></app-pko-fibaro-banner>

<div class="content">
  <div class="card white sign-up-card">
    <div class="title">
      <ng-container *ngIf="!signedUp else signed_up_title">
        Załóż konto
      </ng-container>
      <ng-template #signed_up_title>
        Dziękujemy za założenie konta!
      </ng-template>
    </div>

    <div *ngIf="!signedUp else signed_up_body" class="form-container">
      <app-sign-up-form [processing]="processing" (submitForm)="signUp($event)" [corp]="true" [(userAlreadyExists)]="userAlreadyExists"></app-sign-up-form>
    </div>

    <ng-template #signed_up_body>
      <div class="body">
        Dokończ rejestrację przez link aktywacyjny wysłany na e-mail.
      </div>
    </ng-template>
  </div>

  <div class="card sign-in-card">
    <div class="title">Mam konto</div>
    <a [routerLink]="'/konto-korporacyjne/logowanie'" class="btn small sign-in-btn">ZALOGUJ SIĘ</a>
  </div>
</div>
